const Paths = {
  // ==================================================
  // Rotas
  // ==================================================
  about: '/about',
  contact: '/contact',
  faq: '/faq',
  home: '/',
  how: '/how',
  innovation: '/innovation',
  solution: '/solution',
  team: '/team',
  download: '/download',

  // ==================================================
  // Validar rotas publicas
  // ==================================================
  get publicRoutes() {
    return [
      `^${this.about}$`,
      `^${this.contact}$`,
      `^${this.faq}$`,
      `^${this.home}$`,
      `^${this.how}$`,
      `^${this.innovation}$`,
      `^${this.solution}$`,
      `^${this.team}$`,
      `^${this.download}$`,
    ];
  },
  isPublic(route) {
    return !!this.publicRoutes.find(path => RegExp(path).test(route));
  },
};

export const route = (path, component, opts = {}) => ({
  path,
  component,
  exact: true,
  roles: opts.roles || [],
  employeeOnly: opts.employeeOnly,
});

export default Paths;
