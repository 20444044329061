// Tema

// Utilizar o "hexToRgb" para converter as cores quando necessario

// Seguir esse padrao de responsividade
// @media (max-width: 599px) {}
// @media (min-width: 600px) and (max-width: 959px) {}
// @media (min-width: 960px) and (max-width: 1279px) {}
// @media (min-width: 1280px) and (max-width: 1919px) {}
// @media (min-width: 1920px) {}

const blueColor = ['#2A2E43', '#43425D', '#485E7C', '#7197FF']; // [0] Cor padrao STARGRID
const greenColor = ['#10958C', '#88E5DF', '#9FF480', '#4CC6BE', '#42C6BE', '#76C630']; // [1] VERDE padrao STARGRID
const purpleColor = ['#835BDB', '#CE7DFF', '#DBCEF5', '#8559DB', '#E0D6F5', '#C282FD']; // [1] ROXO padrao STARGRID
const greyColor = ['#78849E', '#9B9C9E', '#D2D5D9', '#F0F0F0', '#E9EBF0', '#707070', '#F4F4F4']; // CINZA
const redColor = ['#910722', '#BE2A2A', '#EF5051', '#ff6347', '#EB5051'];
const orangeColor = ['#FC951D', '#FABF1D', '#FD961C'];
const pinkColor = ['#F5DAE7'];
const whiteColor = '#FFF';
const blackColor = '#000';
const tomatoColor = 'tomato';
const transparentColor = 'transparent';

const hexToRgb = (input) => {
  input += '';
  input = input.replace('#', '');
  const hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error('input is not a valid hex color.');
  }
  if (input.length === 3) {
    const first = input[0];
    const second = input[1];
    const last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  const first = input[0] + input[1];
  const second = input[2] + input[3];
  const last = input[4] + input[5];
  return `${parseInt(first, 16)}, ${parseInt(second, 16)}, ${parseInt(last, 16)}`;
};

export {
  blueColor,
  greenColor,
  purpleColor,
  whiteColor,
  blackColor,
  greyColor,
  redColor,
  orangeColor,
  pinkColor,
  tomatoColor,
  transparentColor,
  hexToRgb,
};
