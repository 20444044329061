import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 161.57 121.12" {...props}>
      <defs>
        <style>{'.prefix__cls-3{fill:#00988d}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <circle
            cx={46.05}
            cy={56.92}
            r={46.05}
            opacity={0.1}
            fill="#00988d"
          />
          <circle cx={132.41} cy={79.39} r={29.16} fill="#d0ecf2" />
          <path
            className="prefix__cls-3"
            d="M130.76 84.77v-2a2 2 0 002-2 12.11 12.11 0 10-24.21 0 2 2 0 002 2v2a10.1 10.1 0 0020.19 0zm-10.09-12.11a8.08 8.08 0 017.64 5.47 15.28 15.28 0 01-4.2-2.87 2 2 0 00-2.85 0 28 28 0 01-8.26 2.86 8.09 8.09 0 017.67-5.46zm0 18.16a6.05 6.05 0 01-6.06-6V81.9a39.22 39.22 0 007.89-2.67 18.31 18.31 0 004.22 2.6v2.94a6.05 6.05 0 01-6.05 6.05z"
          />
          <path
            className="prefix__cls-3"
            d="M138.83 66.66v-48.5a10.11 10.11 0 00-10.09-10.09h-10.09v-2a6.06 6.06 0 00-12.11 0v2H90.39v-2a6.06 6.06 0 00-12.11 0v2H62.14v-2A6.06 6.06 0 0050 6.05v2H39.94a10.11 10.11 0 00-10.1 10.09v64.61a10.11 10.11 0 0010.1 10.09h50.55a30.25 30.25 0 1048.34-26.18zM110.57 6.05a2 2 0 014 0v8.08a2 2 0 11-4 0zm-28.25 0a2 2 0 014 0v8.08a2 2 0 11-4 0zm-28.26 0a2 2 0 014 0v8.08a2 2 0 01-4 0zm-14.12 6.06H50v2a6.06 6.06 0 0012.11 0v-2h16.17v2a6.06 6.06 0 0012.11 0v-2h16.15v2a6.06 6.06 0 0012.11 0v-2h10.09a6.05 6.05 0 016 6.05v10.1H33.88v-10.1a6.05 6.05 0 016.06-6.05zm-6.06 70.64V32.29h100.91v31.78a30.21 30.21 0 00-44.3 24.73H39.94a6.05 6.05 0 01-6.06-6.05zM130.76 115a25.86 25.86 0 01-8.08 1.92v-9.52l4.08-8.16a6.06 6.06 0 014 5.67zm-10.09-12.58l-1.78-3.57h3.55zm-6.1-3.18l4.08 8.16v9.6a26.2 26.2 0 01-8.08-1.92V105a6.06 6.06 0 014-5.72zm20.22 13.62v-8a10.09 10.09 0 00-10.09-10h-8.07A10.09 10.09 0 00106.54 105v8a26.24 26.24 0 1128.25 0z"
          />
          <path
            className="prefix__cls-3"
            d="M74.25 40.37a8.07 8.07 0 108.07 8.07 8.07 8.07 0 00-8.07-8.07zm0 12.1a4 4 0 114-4 4 4 0 01-4 4zM94.43 56.51a8.07 8.07 0 10-8.07-8.07 8.07 8.07 0 008.07 8.07zm0-12.11a4 4 0 11-4 4 4 4 0 014-4zM114.61 56.51a8.07 8.07 0 10-8.07-8.07 8.07 8.07 0 008.07 8.07zm0-12.11a4 4 0 11-4 4 4 4 0 014-4zM54.06 40.37a8.07 8.07 0 108.08 8.07 8.07 8.07 0 00-8.08-8.07zm0 12.1a4 4 0 114-4 4 4 0 01-4 4zM74.25 60.55a8.07 8.07 0 108.07 8.07 8.07 8.07 0 00-8.07-8.07zm0 12.11a4 4 0 114-4 4 4 0 01-4 4zM91.74 76.23l1.34-3.8A4 4 0 1197.5 66l3.06-2.63a8.07 8.07 0 10-8.82 12.87zM54.06 60.55a8.07 8.07 0 108.08 8.07 8.07 8.07 0 00-8.08-8.07zm0 12.11a4 4 0 114-4 4 4 0 01-4 4z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
