import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 161.57 120.55" {...props}>
      <defs>
        <style>{'.prefix__cls-3{fill:#00988d}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <circle
            cx={46.05}
            cy={57.98}
            r={46.05}
            opacity={0.1}
            fill="#00988d"
          />
          <circle cx={132.41} cy={80.45} r={29.16} fill="#d0ecf2" />
          <path
            className="prefix__cls-3"
            d="M122.53 51.66A25.83 25.83 0 1096.7 25.83a25.83 25.83 0 0025.83 25.83zm0-47.35A21.53 21.53 0 11101 25.83a21.52 21.52 0 0121.53-21.52z"
          />
          <path
            className="prefix__cls-3"
            d="M122.53 32.29a6.44 6.44 0 002.16-12.52v-9h-4.31v9a6.47 6.47 0 00-3.91 3.91h-6.85V28h6.85a6.45 6.45 0 006.06 4.29zm0-8.61a2.15 2.15 0 11-2.15 2.15 2.16 2.16 0 012.15-2.15zM23.51 75.34h38.75a4.29 4.29 0 004.3-4.3V40.9a4.29 4.29 0 00-4.3-4.3h-8.61v-2.16h-4.31v2.16H36.43v-2.16h-4.31v2.16h-8.61a4.29 4.29 0 00-4.3 4.3V71a4.29 4.29 0 004.3 4.34zm0-4.3V49.51h38.75V71zm8.61-30.14v2.15h4.31V40.9h12.91v2.15h4.31V40.9h8.61v4.31H23.51V40.9z"
          />
          <path
            className="prefix__cls-3"
            d="M27.82 53.82h4.3v4.3h-4.3zM36.43 53.82h4.3v4.3h-4.3zM45 53.82h4.3v4.3H45zM53.65 53.82H58v4.3h-4.3zM27.82 62.43h4.3v4.3h-4.3zM36.43 62.43h4.3v4.3h-4.3zM45 62.43h4.3v4.3H45zM53.65 62.43H58v4.3h-4.3zM146.21 103.33h-6.45V51.66h-4.31v51.67H32.12V79.65h-4.3v23.68h-6.46a2.15 2.15 0 00-2.15 2.15v8.61a6.46 6.46 0 006.45 6.46h116.25a6.46 6.46 0 006.46-6.46v-8.61a2.16 2.16 0 00-2.16-2.15zm-71 4.3H92.4v2.16H75.18zm68.88 6.46a2.15 2.15 0 01-2.15 2.15H25.66a2.15 2.15 0 01-2.15-2.15v-6.46h47.36v2.16a4.31 4.31 0 004.31 4.3H92.4a4.31 4.31 0 004.3-4.3v-2.16h47.36zM38.58 25.83H92.4v-4.3H38.58a10.76 10.76 0 00-10.76 10.76h4.3a6.46 6.46 0 016.46-6.46z"
          />
          <path
            className="prefix__cls-3"
            d="M40.73 94.72h86.11V99H40.73zM77.33 73.19A17.22 17.22 0 1094.55 56a17.22 17.22 0 00-17.22 17.19zm17.22-12.91a12.92 12.92 0 11-12.92 12.91 12.92 12.92 0 0112.92-12.91z"
          />
          <path
            className="prefix__cls-3"
            d="M92.4 79.65a2.16 2.16 0 001.52-.65l8.61-8.61-3-3-7.13 7.06-2.79-2.78-3 3L90.87 79a2.2 2.2 0 001.53.65zM42.89 79.65v8.61A2.15 2.15 0 0045 90.41h17.26v-4.3H47.19v-6.46zM66.56 86.11h4.31v4.3h-4.31zM75.18 86.11h4.3v4.3h-4.3zM124.69 73.19V56h-4.31v15h-4.3v4.3h6.45a2.16 2.16 0 002.16-2.11zM75.18 43.05h4.3v4.31h-4.3zM83.79 43.05h4.3v4.31h-4.3zM92.4 43.05h4.3v4.31h-4.3z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
