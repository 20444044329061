import React, { memo } from 'react';

// Componentes
import Header from './Header';
import Footer from './Footer';

function Layout(props) {
  return (
    <div className="page-wrapper">
      <Header />
      {props.children}
      <Footer />
    </div>
  );
}

export default memo(Layout);
