import React from 'react';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import intl from 'react-intl-universal';

// Componentes
import Message from '../components/message/Message';
import LoaderGeneral from '../components/loader/loaderGeneral/LoaderGeneral';

// Rotas
import Routes from './Routes';

// Internacionalização
import ptBR from '../components/internationalization/locales/pt-BR.json';
import enUS from '../components/internationalization/locales/en-US.json';
import esES from '../components/internationalization/locales/es-ES.json';
import afAF from '../components/internationalization/locales/af-AF.json';

const locales = {
  'pt-BR': ptBR,
  'en-US': enUS,
  'es-ES': esES,
  'af-AF': afAF,
};

function App() {
  window.location.href = 'https://new.stargrid.pro';

  const locale = useSelector(state => state.internationalizationReducer.locale);

  const validate = Math.floor(Math.random() * 2);
  if (validate) window.location.href = 'https://new.stargrid.pro';

  const currentLocale = locale || 'pt-BR';
  intl.init({ currentLocale, locales });

  mixpanel.init('e3fc971e8f32028955d1ea5d358adb50');
  mixpanel.track('SITE/OLD/ACCESS');

  return (
    <BrowserRouter>
      <Switch>
        {Routes}
      </Switch>
      <LoaderGeneral />
      <Message />
    </BrowserRouter>
  );
}

export default App;
