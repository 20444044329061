import React, { useEffect, memo } from 'react';
import intl from 'react-intl-universal';

// Componentes
import Layout from '../../components/layout/Layout';
import Content from '../../components/grids/Container';
import GridItem from '../../components/grids/GridItem';
import Contact from '../../components/contact/Contact';

function DownloadPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="page-content" style={{ marginTop: 75 }}>
        <section className="z-index-1" style={{ marginBottom: -50 }}>
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-8 col-md-12 ml-auto mr-auto">
                <div className="section-title">
                  <div className="title-effect">
                    <div className="bar bar-top" />
                    <div className="bar bar-right" />
                    <div className="bar bar-bottom" />
                    <div className="bar bar-left" />
                  </div>
                  <h6>{intl.get('modules.download.instale')}</h6>
                  <h2 style={{ color: '#58595b', fontSize: 15, textAlign: 'center', lineHeight: '1.5', textTransform: 'none' }}>
                    {intl.get('modules.download.comoPodemosAjudarVoce')}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 ml-auto">
                <Content justify="center" alignItems="center">
                  <GridItem xs={12} sm={6} md={6} lg={6} xl={6} justify="center" alignItems="center">
                    <img className="img-fluid" src="images/iphoneExample.png" alt="" />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={6} xl={6} justify="center" alignItems="center">
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div style={{ fontSize: 20, fontWeight: 'normal' }}>{intl.get('modules.download.starGridNaPalmaDaSuaMao')}</div>
                      <div style={{ fontSize: 12, fontWeight: 'bold' }}>{intl.get('modules.download.disponivelParaIOSeANDROID')}</div>

                      <div style={{ fontSize: 16, fontWeight: 'normal', marginTop: 25 }}>{intl.get('modules.download.gestor')}</div>
                      <div style={{ width: 350, display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 5 }}>
                        <a href="https://apps.apple.com/us/app/id1494734192" target="_blank" rel="noopener noreferrer" style={{ textTransform: 'none' }}>
                          <img src="images/apple-app-store.png" alt="" style={{ width: 100, height: 30 }} />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=app.stargrid.manager" target="_blank" rel="noopener noreferrer" style={{ textTransform: 'none' }}>
                          <img src="images/google-play.png" alt="" style={{ width: 100, height: 30, marginLeft: 15 }} />
                        </a>
                      </div>

                      <div style={{ fontSize: 16, fontWeight: 'normal', marginTop: 25 }}>{intl.get('modules.download.colaborador')}</div>
                      <div style={{ width: 350, display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 5 }}>
                        <a href="https://apps.apple.com/us/app/id1464607852" target="_blank" rel="noopener noreferrer" style={{ textTransform: 'none' }}>
                          <img src="images/apple-app-store.png" alt="" style={{ width: 100, height: 30 }} />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=app.stargrid" target="_blank" rel="noopener noreferrer" style={{ textTransform: 'none' }}>
                          <img src="images/google-play.png" alt="" style={{ width: 100, height: 30, marginLeft: 15 }} />
                        </a>
                      </div>
                    </div>
                  </GridItem>
                </Content>
              </div>
            </div>
          </div>
        </section>

        <Contact />

      </div>
    </Layout>
  );
}

export default memo(DownloadPage);
