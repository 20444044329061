import React, { useEffect, memo } from 'react';
import intl from 'react-intl-universal';

// Componentes
import Layout from '../../components/layout/Layout';
import Contact from '../../components/contact/Contact';

function FaqPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>

      <div className="page-content" style={{ marginTop: 75 }}>
        <section className="z-index-1" style={{ marginBottom: -50 }}>
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-8 col-md-12 ml-auto mr-auto">
                <div className="section-title">
                  <div className="title-effect">
                    <div className="bar bar-top" />
                    <div className="bar bar-right" />
                    <div className="bar bar-bottom" />
                    <div className="bar bar-left" />
                  </div>
                  <h6>{intl.get('modules.faq.perguntasFrequentes')}</h6>
                  <h2 style={{ color: '#58595b', fontSize: 15, textAlign: 'center', lineHeight: '1.5', textTransform: 'none' }}>
                    {intl.get('modules.faq.comoPodemosAjudarVoce')}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 ml-auto">
                <div id="accordion" className="accordion style-1">
                  <div className="card active">
                    <div className="card-header">
                      <h6 className="mb-0">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="true" style={{ textTransform: 'none' }}>
                          {intl.get('modules.faq.quaisOsTiposDeEscalasPossoGerenciarNaStarGrid')}
                        </a>
                      </h6>
                    </div>
                    <div id="collapse1" className="collapse show" data-parent="#accordion">
                      <div className="card-body">
                        {intl.get('modules.faq.quaisOsTiposDeEscalasPossoGerenciarNaStarGridRESPOSTA')}
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse2" style={{ textTransform: 'none' }}>
                          {intl.get('modules.faq.noCalculoAutomaticoDasEscalasDeTrabalhoAsNormasDaCLTSaoRespeitadas')}
                        </a>
                      </h6>
                    </div>
                    <div id="collapse2" className="collapse" data-parent="#accordion">
                      <div className="card-body">
                        {intl.get('modules.faq.noCalculoAutomaticoDasEscalasDeTrabalhoAsNormasDaCLTSaoRespeitadasRESPOSTA')}
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse3" style={{ textTransform: 'none' }}>
                          {intl.get('modules.faq.comoStarGridAjudaNoDimensionamentoDeEscalas')}
                        </a>
                      </h6>
                    </div>
                    <div id="collapse3" className="collapse" data-parent="#accordion">
                      <div className="card-body">
                        {intl.get('modules.faq.comoStarGridAjudaNoDimensionamentoDeEscalasRESPOSTA')}
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse4" style={{ textTransform: 'none' }}>
                          {intl.get('modules.faq.queroAcompanharNumerosDaMinhaEmpresaComoFaco')}
                        </a>
                      </h6>
                    </div>
                    <div id="collapse4" className="collapse" data-parent="#accordion">
                      <div className="card-body">
                        {intl.get('modules.faq.queroAcompanharNumerosDaMinhaEmpresaComoFacoRESPOSTA')}
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse5" style={{ textTransform: 'none' }}>
                          {intl.get('modules.faq.quaisOsBeneficiosDoUsoDaStarGridParaOsColaboradores')}
                        </a>
                      </h6>
                    </div>
                    <div id="collapse5" className="collapse" data-parent="#accordion">
                      <div className="card-body">
                        {intl.get('modules.faq.quaisOsBeneficiosDoUsoDaStarGridParaOsColaboradoresRESPOSTA')}
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse6" style={{ textTransform: 'none' }}>
                          {intl.get('modules.faq.comoStarGridBeneficiaOsGestoresDeEscalas')}
                        </a>
                      </h6>
                    </div>
                    <div id="collapse6" className="collapse" data-parent="#accordion">
                      <div className="card-body">
                        {intl.get('modules.faq.comoStarGridBeneficiaOsGestoresDeEscalasRESPOSTA')}
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapse6" style={{ textTransform: 'none' }}>
                          {intl.get('modules.faq.quaisOsGanhosTrazidosPeloUsoDaStarGridNaMinhaEmpresa')}
                        </a>
                      </h6>
                    </div>
                    <div id="collapse6" className="collapse" data-parent="#accordion">
                      <div className="card-body">
                        {intl.get('modules.faq.quaisOsGanhosTrazidosPeloUsoDaStarGridNaMinhaEmpresaRESPOSTA')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Contact />

      </div>
    </Layout>
  );
}

export default memo(FaqPage);
