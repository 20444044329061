import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 161.57 124.89" {...props}>
      <defs>
        <style>{'.prefix__cls-3{fill:#00988d}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <circle
            cx={46.05}
            cy={60.69}
            r={46.05}
            opacity={0.1}
            fill="#00988d"
          />
          <circle cx={132.41} cy={83.16} r={29.16} fill="#d0ecf2" />
          <path
            className="prefix__cls-3"
            d="M81.64 85a22.59 22.59 0 1122.59-22.59A22.61 22.61 0 0181.64 85zm0-41.24a18.66 18.66 0 1018.66 18.67 18.68 18.68 0 00-18.66-18.65z"
          />
          <path
            className="prefix__cls-3"
            d="M94.99 78.55L78.97 62.54l14.86-17.43 2.99 2.55-12.5 14.67 13.45 13.44-2.78 2.78z"
          />
          <path
            className="prefix__cls-3"
            d="M60.83 60.47h22.78v3.93H60.83zM118.17 54.14l-.53-1.89a37.41 37.41 0 00-72 0l-.54 1.89-3.78-1.07.53-1.89a41.34 41.34 0 0179.55 0l.54 1.89zM81.64 103.76a41.47 41.47 0 01-39.77-30.08l-.53-1.89 3.78-1.07.53 1.9a37.42 37.42 0 0072 0l.53-1.89L122 71.8l-.53 1.89a41.48 41.48 0 01-39.83 30.07z"
          />
          <path
            className="prefix__cls-3"
            d="M125.63 62.75h-12.21V50.54h12.21zm-8.27-3.94h4.34v-4.34h-4.34zM49.87 74.12H37.66v-12.2h12.21zm-8.28-3.93h4.34v-4.34h-4.34z"
          />
          <path
            className="prefix__cls-3"
            d="M94.74 118.93l-1-3.8 1.9-.51a54 54 0 00-.74-104.57L93 9.57l1-3.81 1.9.48a58 58 0 01.78 112.18zM69.74 119.19l-1.91-.47a58 58 0 01-.59-112.43l1.9-.49 1 3.81-1.91.49a54 54 0 00.56 104.8l1.91.47zM81.64 14.84a7.42 7.42 0 117.43-7.42 7.42 7.42 0 01-7.43 7.42zm0-10.91a3.49 3.49 0 103.49 3.49 3.5 3.5 0 00-3.49-3.49zM81.64 124.89a7.43 7.43 0 117.43-7.43 7.44 7.44 0 01-7.43 7.43zm0-10.92a3.5 3.5 0 103.5 3.49 3.49 3.49 0 00-3.5-3.46z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
