import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 161.57 122.2" {...props}>
      <defs>
        <style>{'.prefix__cls-3{fill:#00988d}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <circle
            cx={46.05}
            cy={58.31}
            r={46.05}
            opacity={0.1}
            fill="#00988d"
          />
          <circle cx={132.41} cy={80.78} r={29.16} fill="#d0ecf2" />
          <path
            className="prefix__cls-3"
            d="M68.46 97.76h4.07v4.07h-4.07zM39.94 97.76h4.07v4.07h-4.07zM54.2 93.69h4.07a2 2 0 012 2h4.07a6.11 6.11 0 00-6.11-6.11v-2H54.2v2a6.11 6.11 0 000 12.22h4.07a2 2 0 010 4.08H54.2a2 2 0 01-2-2h-4.11A6.1 6.1 0 0054.2 110v2h4.07v-2a6.11 6.11 0 000-12.22H54.2a2 2 0 110-4.07zM113.26 34.62h4.08a2 2 0 012 2h4.08a6.12 6.12 0 00-6.11-6.11v-2h-4.08v2a6.11 6.11 0 100 12.22h4.08a2 2 0 010 4.07h-4.08a2 2 0 01-2-2h-4.08a6.12 6.12 0 006.11 6.11v2h4.08v-2a6.11 6.11 0 000-12.22h-4.08a2 2 0 010-4.08z"
          />
          <path
            className="prefix__cls-3"
            d="M139.74 40.73a24.45 24.45 0 00-14.26-22.19v-8.36A10.19 10.19 0 00115.3 0H62.35a10.2 10.2 0 00-10.19 10.18v47.36a4.25 4.25 0 00-2-.51 7.68 7.68 0 00-4.08 1.28 3.85 3.85 0 01-2 .75 2 2 0 00-2 2 2 2 0 00.13.72l4.31 11.5H44v3.31a24.41 24.41 0 00-12.2 21.17v10.18a14.27 14.27 0 0014.25 14.26h69.25a10.19 10.19 0 0010.18-10.2V62.93a24.45 24.45 0 0014.26-22.2zM76.6 4.07H101v2a2 2 0 01-2 2H78.64a2 2 0 01-2-2zM46.73 62.55a13.41 13.41 0 001.36-.7 3.85 3.85 0 012-.75c.34 0 1 .77 1.52 1.34a6.18 6.18 0 004.59 2.73 6.17 6.17 0 004.58-2.73c.51-.57 1.18-1.34 1.53-1.34a3.85 3.85 0 012 .75 14.39 14.39 0 001.35.7l-4 10.77H50.77zm-.68 55.58a10.2 10.2 0 01-10.18-10.19V97.76a20.39 20.39 0 0112.65-18.84 8.17 8.17 0 00-.43 2.55v2h4.07v-2a4.08 4.08 0 118.15 0v2h4.07v-2a7.89 7.89 0 00-.38-2.55 20.39 20.39 0 0112.6 18.84v10.18a10.19 10.19 0 01-10.18 10.19zm75.36-6.13a6.12 6.12 0 01-6.11 6.11H76.38a14.22 14.22 0 004.3-10.19V97.76a24.46 24.46 0 00-12.22-21.13v-3.31h-2.41l4.31-11.5a2 2 0 00-1.19-2.63 2.17 2.17 0 00-.71-.13 3.85 3.85 0 01-2-.75A7.67 7.67 0 0062.35 57a6.21 6.21 0 00-4.59 2.73c-.51.57-1.18 1.34-1.52 1.34V10.18a6.11 6.11 0 016.11-6.11h10.18v2a6.11 6.11 0 006.11 6.11H99a6.12 6.12 0 006.11-6.11v-2h10.19a6.12 6.12 0 016.11 6.11v6.93a24.44 24.44 0 100 47.25zm-6.11-50.9a20.37 20.37 0 1120.37-20.37A20.37 20.37 0 01115.3 61.1z"
          />
          <path
            className="prefix__cls-3"
            d="M105.56 70l-8.14 10.19A2 2 0 0099 83.5h2v14.26h4.08V81.47a2 2 0 00-1.88-2l3.91-4.89 3.92 4.89a2 2 0 00-1.88 2v20.36h4.07V83.5h2a2 2 0 002-2 2.08 2.08 0 00-.45-1.28L108.74 70a2.1 2.1 0 00-3-.2 2 2 0 00-.21.2zM88.38 53.72a2.12 2.12 0 00-3-.21l-.2.21-8.13 10.18a2 2 0 001.59 3.31h2v14.26h4.07v-16.3a2 2 0 00-1.88-2l3.92-4.89 3.91 4.89a2 2 0 00-1.88 2v20.37h4.12V67.21h2a2 2 0 001.59-3.31zM80.47 35.51a2 2 0 00-.24-2.16l-8.15-10.18a2.12 2.12 0 00-3-.21 2 2 0 00-.2.21l-8.13 10.18a2 2 0 001.6 3.31h2v14.26h4.08v-16.3a2 2 0 00-1.88-2l3.91-4.89 3.92 4.89a2 2 0 00-1.88 2V55h4.1V36.66h2a2 2 0 001.87-1.15zM88.82 89.61h4.07v4.07h-4.07zM88.82 97.76h4.07v4.07h-4.07zM109.19 105.91h4.07v4.07h-4.07zM101.04 101.83h4.07v4.07h-4.07z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
