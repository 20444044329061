import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 59.86 76.19" {...props}>
      <defs>
        <style>
          {
            '.prefix__cls-11,.prefix__cls-22{fill:none;stroke:#3f2963;stroke-width:2px}.prefix__cls-11{stroke-linejoin:round}.prefix__cls-22{stroke-miterlimit:10}'
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-11"
            d="M7.61 13.7v5.42c0 7.31 4.52 13.68 11.24 17.07a3.22 3.22 0 011.58 2.61 3.15 3.15 0 01-1.56 2.61C12.14 44.8 7.61 51.17 7.61 58.49v6.29s8.51 2.83 22.31 2.83a87.78 87.78 0 0022.34-2.83v-6.29c0-7.2-4.37-13.49-10.92-16.92a3.24 3.24 0 01-1.86-2.77 3.44 3.44 0 012-2.84c6.45-3.45 10.79-9.69 10.79-16.84v-5.37"
          />
          <path
            className="prefix__cls-11"
            d="M44.59 60.2c0-4.44-6.56-12-14.66-12s-14.66 7.51-14.66 12a63 63 0 0014.21 1.29 78 78 0 0015.11-1.29zM18.77 28.94S22.33 26 30.79 24a77.8 77.8 0 0114.34-2s-1.64 6.84-9.66 10.32c-10.71 4.68-16.7-3.38-16.7-3.38z"
          />
          <path
            className="prefix__cls-22"
            d="M29.93 33.75v14.5M29.93 1C14 1 1 3.59 1 6.79v3.28c0 3.19 13 5.79 28.93 5.79s28.93-2.6 28.93-5.79V6.79C58.86 3.59 45.91 1 29.93 1zM7.61 62C3.48 63 1 64.32 1 65.72V69c0 3.2 13 6.19 28.93 6.19s28.93-3 28.93-6.19v-3.28c0-1.4-2.48-2.68-6.6-3.68"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
