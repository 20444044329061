import { renderRoutes } from 'react-router-config';

// Permissoes e Rotas
import Paths, { route } from '../configs/Paths';
// import { ADM, MAN, EMP } from '../configs/Roles';

// Componentes
import PageRoot from '../components/pageRoot/PageRootPage';

// Modulos
import ContactPage from '../modules/contact/ContactPage';
import DownloadPage from '../modules/download/DownloadPage';
import FaqPage from '../modules/faq/FaqPage';
import HomePage from '../modules/home/HomePage';
import HowPage from '../modules/how/HowPage';

export const routes = [
  route(Paths.contact, ContactPage),
  route(Paths.download, DownloadPage),
  route(Paths.faq, FaqPage),
  route(Paths.home, HomePage),
  route(Paths.how, HowPage),
];

export default renderRoutes([
  {
    component: PageRoot,
    routes,
  },
]);
