import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 93.8 93.8" {...props}>
      <defs>
        <style>{'.prefix__cls-1{fill:#00a79f}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M27.23 6.05h9.08v3h-9.08zM21.18 6.05h3v3h-3zM13 69.59h-.9a3 3 0 00-3 3v3a3 3 0 003 3h.9a3 3 0 002.14-.89l3.65-3.65-3.65-3.65a3 3 0 00-2.14-.81zm0 6h-.9v-3h.9l1.51 1.51zM30.26 69.59h-3a3 3 0 00-3 3v3a3 3 0 003 3h3a3 3 0 003-3v-3a3 3 0 00-3-3zm-3 6v-3h3v3zM44.5 78.67h.89a3 3 0 003-3v-3a3 3 0 00-3-3h-.89a3 3 0 00-2.14.89l-3.65 3.65 3.65 3.65a3 3 0 002.14.81zm0-6h.89v3h-.89L43 74.13z"
          />
          <path
            className="prefix__cls-1"
            d="M86.23 18.15H57.49V7.56A7.57 7.57 0 0049.92 0H7.56A7.57 7.57 0 000 7.56v78.67a7.58 7.58 0 007.56 7.57h42.36a7.58 7.58 0 007.57-7.57V66.57h28.74A7.58 7.58 0 0093.8 59V25.72a7.58 7.58 0 00-7.57-7.57zm4.54 7.57v1.51H33.28v-1.51a4.55 4.55 0 014.54-4.54h48.41a4.54 4.54 0 014.54 4.54zM7.56 3h42.36a4.53 4.53 0 014.54 4.53v4.57H3V7.56A4.53 4.53 0 017.56 3zm42.36 87.77H7.56A4.53 4.53 0 013 86.23v-3.06a7.45 7.45 0 004.53 1.55h42.39a7.47 7.47 0 004.54-1.55v3.06a4.54 4.54 0 01-4.54 4.54zm4.54-13.61a4.54 4.54 0 01-4.54 4.54H7.56A4.54 4.54 0 013 77.16v-62h51.46v3H37.82a7.58 7.58 0 00-7.56 7.57V59a7.58 7.58 0 007.56 7.57h16.64zm31.77-13.62H37.82A4.55 4.55 0 0133.28 59V30.26h57.49V59a4.54 4.54 0 01-4.54 4.54z"
          />
          <path
            className="prefix__cls-1"
            d="M84.72 33.28H39.33a3 3 0 00-3 3v21.21a3 3 0 003 3h45.39a3 3 0 003-3V36.31a3 3 0 00-3-3.03zm0 12.11h-9.08v-9.08h9.08zm-24.21 3v9.08h-9.07v-9.06zm-9.07-3v-9.08h9.07v9.08zm12.1 3h9.08v9.08h-9.08zm0-3v-9.08h9.08v9.08zm-15.13-9.08v9.08h-9.08v-9.08zm-9.08 12.1h9.08v9.08h-9.08zm36.31 9.08v-9.08h9.08v9.08zM27.23 18.15H6.05v21.18h21.18zm-3 18.16H9.08V21.18h15.13zM27.23 42.36H6.05v21.18h21.18zm-3 18.15H9.08V45.39h15.13z"
          />
          <path
            className="prefix__cls-1"
            d="M12.6 35.78l4-4.71 4 4.71 2.3-2-4.35-5.07L23 23.68l-2.3-2-4 4.71-4-4.71-2.29 2 4.34 5.06-4.34 5.07z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
