import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg {...props} viewBox="0 0 65.09 65.09">
      <defs>
        <style>{'.prefix__cls-1{fill:#265f50!important}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M52.07 35.8h-8.68a5.43 5.43 0 010-10.85h8.68a5.43 5.43 0 010 10.85zm-8.68-8.68a3.26 3.26 0 000 6.51h8.68a3.26 3.26 0 000-6.51zM52.07 53.15h-8.68a5.42 5.42 0 110-10.84h8.68a5.42 5.42 0 110 10.84zm-8.68-8.67a3.25 3.25 0 000 6.5h8.68a3.25 3.25 0 000-6.5zM27.12 32.54H8.68a1.09 1.09 0 110-2.17h18.44a1.09 1.09 0 010 2.17zM33.63 28.2h-25a1.09 1.09 0 110-2.17h25a1.09 1.09 0 010 2.17zM27.12 49.9H8.68a1.09 1.09 0 110-2.17h18.44a1.09 1.09 0 010 2.17zM33.63 45.56h-25a1.09 1.09 0 110-2.17h25a1.09 1.09 0 010 2.17z"
          />
          <path
            className="prefix__cls-1"
            d="M60.2 65.09H4.88A4.89 4.89 0 010 60.2V4.88A4.89 4.89 0 014.88 0H60.2a4.89 4.89 0 014.89 4.88V60.2a4.89 4.89 0 01-4.89 4.89zM4.88 2.17a2.72 2.72 0 00-2.71 2.71V60.2a2.72 2.72 0 002.71 2.72H60.2a2.72 2.72 0 002.72-2.72V4.88a2.72 2.72 0 00-2.72-2.71z"
          />
          <path
            className="prefix__cls-1"
            d="M9.76 10.85H5.42a1.09 1.09 0 01-1.08-1.09V5.42a1.08 1.08 0 011.08-1.08h4.34a1.08 1.08 0 011.09 1.08v4.34a1.09 1.09 0 01-1.09 1.09zM6.51 8.68h2.17V6.51H6.51zM18.44 10.85H14.1A1.08 1.08 0 0113 9.76V5.42a1.08 1.08 0 011.1-1.08h4.34a1.08 1.08 0 011.09 1.08v4.34a1.09 1.09 0 01-1.09 1.09zm-3.25-2.17h2.17V6.51h-2.17zM27.12 10.85h-4.34a1.08 1.08 0 01-1.08-1.09V5.42a1.08 1.08 0 011.08-1.08h4.34a1.08 1.08 0 011.08 1.08v4.34a1.08 1.08 0 01-1.08 1.09zm-3.26-2.17H26V6.51h-2.14z"
          />
          <path
            className="prefix__cls-1"
            d="M64 15.19H1.08A1.09 1.09 0 010 14.1V4.88A4.89 4.89 0 014.88 0H60.2a4.89 4.89 0 014.89 4.88v9.22A1.09 1.09 0 0164 15.19zM2.17 13h60.75V4.88a2.72 2.72 0 00-2.72-2.71H4.88a2.72 2.72 0 00-2.71 2.71zM52.07 53.15a5.42 5.42 0 115.42-5.42 5.43 5.43 0 01-5.42 5.42zm0-8.67a3.25 3.25 0 103.25 3.25 3.26 3.26 0 00-3.25-3.25zM43.39 35.8a5.43 5.43 0 115.42-5.43 5.43 5.43 0 01-5.42 5.43zm0-8.68a3.26 3.26 0 103.25 3.25 3.26 3.26 0 00-3.25-3.25z"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoSvgComponent = React.memo(SvgComponent);
export default MemoSvgComponent;
