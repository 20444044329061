const INITIAL_STATE = {
  statusModalOpenSite: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'OPEN_MODAL_OPEN_SITE_FETCHED':
      return { ...state, statusModalOpenSite: action.payload };
    case 'CLOSE_MODAL_OPEN_SITE_FETCHED':
      return { ...state, statusModalOpenSite: action.payload };
    default:
      return state;
  }
};
