import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import styled from 'styled-components';
import { Modal } from '@material-ui/core';

// Componentes
import LoaderIcon from '../../icons/Loader';

function LoaderGeneral() {
  const statusLoader = useSelector(state => state.loaderGeneralReducer.statusLoader);

  return (
    <Modal
      open={statusLoader || false}
      disableBackdropClick
    >
      <Content>
        <LoaderIcon />
        <Text>{intl.get('components.loader.loaderGeneral.carregandoAguarde')}</Text>
      </Content>
    </Modal>
  );
}

export default memo(LoaderGeneral);

// ==================================================
// Estilos StarGrid
// ==================================================
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 0px transparent;
  color: #FFF;
`;
const Text = styled.div`
  font-size: 12px;
  position: absolute;
  margin-top: 60px;
`;
