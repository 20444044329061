import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import Promise from 'redux-promise';
import Multi from 'redux-multi';
import Thunk from 'redux-thunk';

// Sistema
import App from './main/App';
import Reducers from './main/Reducers';

// DevTools
const DevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

// Middlewares
const Store = applyMiddleware(Multi, Thunk, Promise)(createStore)(Reducers, DevTools);

render(
  <Provider store={Store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
