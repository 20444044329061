import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 85.82 66.22" {...props}>
      <defs>
        <style>
          {
            '.prefix__cls-11{fill:none;stroke:#3f2963;stroke-linejoin:round;stroke-width:2px}'
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-11"
            d="M42.14 18.67C47.74 26.79 57 33.26 59 35v30.25h20.65V59a4.74 4.74 0 005.17-4.59V37.09c0-8.49-3.76-11.08-10.9-11.27l-4.29 4.29-6.34-6.34c-6.43-3.11-14.13-10.6-17.12-15.32"
          />
          <path
            className="prefix__cls-11"
            d="M69.63 20a9.52 9.52 0 10-9.53-9.52A9.52 9.52 0 0069.63 20zM6.18 59v6.24h20.63V34.99c2.8-2.36 12.94-8.72 18.57-23 .89-2.26 1.67-5-1.74-6.28-2.42-.91-3.78-.55-6.34 3.57-3 4.78-8 11.29-14.79 14.55l-6.31 6.28-4.29-4.29C4.76 26 1 28.6 1 37.09v17.3A4.65 4.65 0 006.18 59z"
          />
          <path
            className="prefix__cls-11"
            d="M16.2 20a9.52 9.52 0 10-9.52-9.52A9.52 9.52 0 0016.2 20z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
