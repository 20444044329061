import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 71.87 64.41" {...props}>
      <defs>
        <style>{'.prefix__cls-111111{fill:#3f2963}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-111111"
            d="M50.08 10.66H26V0h24.08zM28.19 8.48H47.9v-6.3H28.19z"
          />
          <path
            className="prefix__cls-111111"
            d="M60.32 64.41H15.77V4.24H27.1v2.18h-9.15v55.81h40.18v-3.58h2.19v5.76zM60.32 28.93h-2.19V6.42h-9.14V4.24h11.33v24.69zM32.91 4.24h10.26v2.18H32.91z"
          />
          <path
            className="prefix__cls-111111"
            d="M25.02 24.07l-3-3.16 1.58-1.5 1.42 1.49 4.64-4.89 1.59 1.5-6.23 6.56zM25.02 35.24l-3-3.16 1.58-1.51 1.42 1.5 4.64-4.89 1.59 1.5-6.23 6.56zM25.02 46.79l-3-3.16 1.58-1.51 1.42 1.5 4.64-4.89 1.59 1.5-6.23 6.56zM25.02 58.15l-3-3.17 1.58-1.5 1.42 1.5 4.64-4.9 1.59 1.51-6.23 6.56zM34.28 18.93H53.7v2.18H34.28zM34.28 30.29h12.09v2.18H34.28zM34.28 41.64h8.07v2.18h-8.07zM34.28 53h8.07v2.18h-8.07zM60.89 56.62h-3.33a1.08 1.08 0 01-1-.75l-.94-2.81-.25-.06-2.65 1.3a1.11 1.11 0 01-1.26-.21l-2.35-2.35a1.09 1.09 0 01-.2-1.26l1.32-2.65-.09-.22-2.81-.94a1.08 1.08 0 01-.75-1v-3.36a1.08 1.08 0 01.75-1l2.81-.94.09-.22-1.32-2.65a1.09 1.09 0 01.2-1.26l2.35-2.35a1.09 1.09 0 011.26-.2L55.37 35l.22-.09.94-2.81a1.08 1.08 0 011-.75h3.33a1.08 1.08 0 011 .75l.94 2.81.22.09 2.65-1.32a1.09 1.09 0 011.26.2l2.35 2.35a1.1 1.1 0 01.21 1.26l-1.33 2.65.09.22 2.81.94a1.08 1.08 0 01.75 1v3.33a1.08 1.08 0 01-.75 1l-2.81.94-.09.22 1.33 2.65a1.1 1.1 0 01-.21 1.26L67 54.09a1.11 1.11 0 01-1.26.21L63.08 53l-.22.09-.94 2.81a1.08 1.08 0 01-1.03.72zm-2.54-2.19h1.75l.9-2.53a1.11 1.11 0 01.62-.67l1.13-.46a1.07 1.07 0 01.91 0L66 52l1.24-1.24-1.19-2.39a1.07 1.07 0 010-.91l.47-1.13a1.09 1.09 0 01.66-.62l2.53-.85V43.1l-2.53-.85a1.09 1.09 0 01-.66-.62L66 40.5a1.07 1.07 0 010-.91l1.19-2.39L66 36l-2.39 1.19a1.15 1.15 0 01-.91 0l-1.13-.47a1.11 1.11 0 01-.62-.67l-.85-2.53h-1.75l-.85 2.53a1.11 1.11 0 01-.62.67l-1.13.47a1.11 1.11 0 01-.91 0L52.45 36l-1.24 1.2 1.19 2.39a1.11 1.11 0 010 .91l-.4 1.13a1.11 1.11 0 01-.67.62l-2.53.85v1.75l2.53.85a1.11 1.11 0 01.67.62l.47 1.13a1.11 1.11 0 010 .91l-1.19 2.39L52.45 52l2.39-1.19a1.07 1.07 0 01.91 0l1.13.46a1.11 1.11 0 01.62.67z"
          />
          <path
            className="prefix__cls-111111"
            d="M59.23 49a5 5 0 115-5 5 5 0 01-5 5zm0-7.79A2.81 2.81 0 1062 44a2.81 2.81 0 00-2.77-2.83zM8 61.87a1.09 1.09 0 01-1-.6l-2.55-5.12a1.17 1.17 0 01-.11-.49V10.44a1.09 1.09 0 011.09-1.09h5.12a1.09 1.09 0 011.09 1.09v45.22a1 1 0 01-.12.49L9 61.27a1.1 1.1 0 01-1 .6zM6.52 55.4L8 58.34l1.46-2.94V11.53H6.52z"
          />
          <path
            className="prefix__cls-111111"
            d="M1.09 30A1.09 1.09 0 010 28.93V14.66a1.09 1.09 0 011.09-1.09h3.5a1.1 1.1 0 010 2.19H2.18v13.17A1.09 1.09 0 011.09 30zM8 64.41a1.09 1.09 0 01-1.1-1.09V61a1.09 1.09 0 112.18 0v2.3A1.09 1.09 0 018 64.41zM8 11.53a1.09 1.09 0 01-1.1-1.09V6.23a1.09 1.09 0 112.18 0v4.21A1.09 1.09 0 018 11.53z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
