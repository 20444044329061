const INITIAL_STATE = {
  locale: 'pt-BR',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOCALE_FETCHED':
      return { ...state, locale: action.payload };
    default:
      return state;
  }
};
