import React from 'react';
import styled from 'styled-components';
import { Button, CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Tema
import { purpleColor, greenColor, blueColor, greyColor, whiteColor, redColor, transparentColor } from '../../configs/Theme';

function ButtonDefault(props) {
  return (
    <ButtonSG
      id={props.id}
      noned={props.none ? props.none.toString() : 'false'}
      fullWidth={props.fullWidth || false}
      type={props.type}
      size="small"
      variant="contained"
      disabled={props.disabled}
      height={props.height}
      padding={props.padding}
      colors={props.colors}
      colorsline={props.colorsLine}
      startIcon={
        props.progress ? <CircularProgress size={20} />
          : props.icon ? <Icon icon={props.icon} />
            : props.iconSG ? props.iconSG
              : <div style={{ display: 'none', border: 'solid 0px' }}>x</div>
      }
      onClick={props.onClick}
    >
      {props.label}
    </ButtonSG>
  );
}

export default ButtonDefault;

// ==================================================
// Estilos StarGrid
// ==================================================
const ButtonSG = styled(Button)`
  background-color: transparent !important;
  box-shadow: none !important;
  &:hover {
    box-shadow: 
      0px 3px 1px -2px rgba(0,0,0,0.2),
      0px 2px 2px 0px rgba(0,0,0,0.14),
      0px 1px 5px 0px rgba(0,0,0,0.12) !important;
  }
  .MuiButton-label {
    height: ${props => (props.height ? props.height : '20px')} !important;
    color: ${props => (props.disabled
    ? whiteColor
    : props => (props.colors === 'transparent' ? purpleColor[0]
      : props => (props.colorsline === 'purple' ? purpleColor[0]
        : props => (props.colorsline === 'green' ? greenColor[0]
          : props => (props.colorsline === 'blue' ? blueColor[0]
            : props => (props.colorsline === 'grey' ? greyColor[1]
              : props => (props.colorsline === 'red' ? redColor[1]
                : props => (props.colorsline === 'transparent' ? greenColor[0]
                  : whiteColor)))))))
  )} !important;
  }
  background-image: ${props => (props.disabled
    ? `linear-gradient(to top right, ${greyColor[1]},${greyColor[2]})`
    : props => (props.colors === 'purple' ? `linear-gradient(to top right, ${purpleColor[0]},${purpleColor[1]})`
      : props => (props.colors === 'green' ? `linear-gradient(to top right, ${greenColor[0]},${greenColor[1]})`
        : props => (props.colors === 'blue' ? `linear-gradient(to top right, ${blueColor[0]},${blueColor[1]})`
          : props => (props.colors === 'grey' ? `linear-gradient(to top right, ${greyColor[1]},${greyColor[2]})`
            : props => (props.colors === 'red' ? `linear-gradient(to top right, ${redColor[0]},${redColor[1]})`
              : props => (props.colors === 'transparent' ? `linear-gradient(to top right, ${transparentColor},${transparentColor})`
                : `linear-gradient(to top right, ${whiteColor},${whiteColor})`))))))
  )};
  border: solid 2px ${props => (props.disabled
    ? 'none'
    : props => (props.colorsline === 'purple' ? purpleColor[1]
      : props => (props.colorsline === 'green' ? greenColor[0]
        : props => (props.colorsline === 'blue' ? blueColor[0]
          : props => (props.colorsline === 'grey' ? greyColor[1]
            : props => (props.colorsline === 'red' ? redColor[1]
              : 'none')))))
  )} !important;
  border-radius: 20px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  padding: ${props => (props.padding ? props.padding : '5px 35px')} !important;
  display: ${props => (props.noned === 'true' ? 'none' : 'flex')} !important;
  text-transform: none !important;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: ${greenColor[0]};
`;
