import React from 'react';
import { Grid } from '@material-ui/core';

function GridItem(props) {
  return (
    <Grid
      container
      item
      direction={props.direction || 'row'}
      justify={props.justify || 'flex-start'}
      alignItems={props.alignItems || 'flex-start'}
      xs={props.xs || 12}
      sm={props.sm || 12}
      md={props.md || 12}
      lg={props.lg || 12}
      xl={props.xl || 12}
    >
      {props.children}
    </Grid>
  );
}

export default GridItem;
