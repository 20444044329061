import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';

// Actions
import { sendMessage } from '../../modules/contact/ContactAction';
import { statusLoader } from '../loader/loaderGeneral/LoaderGeneralAction';

function ContactComponent() {
  const dispatch = useDispatch();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [job, setJob] = useState();
  const [department, setDepartment] = useState();

  const handleSubmit = () => {
    dispatch(statusLoader(true));
    dispatch(sendMessage('home', name, job, phone, email, department, ''));
    setName('');
    setEmail('');
    setPhone('');
    setJob('');
    setDepartment('');
  };

  return (
    <section id="contact" style={{ width: '100%' }}>
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-8 col-md-12 ml-auto mr-auto">
            <div className="section-title">
              <div className="title-effect">
                <div className="bar bar-top" />
                <div className="bar bar-right" />
                <div className="bar bar-bottom" />
                <div className="bar bar-left" />
              </div>
              <h6 style={{ color: '#243e64' }}>{intl.get('components.contact.contato')}</h6>
              <h2 style={{ color: '#58595b', fontSize: 15, textAlign: 'center', lineHeight: '1.5', textTransform: 'none' }}>
                {intl.get('components.contact.gostouDigaComoPodemosEntrarEmContatoComVoce')}
              </h2>
            </div>
          </div>
        </div>
        <div className="row" style={{ justifyContent: 'center' }}>
          <div className="subscribe-box">
            <div className="container">
              <div className="row subscribe-inner align-items-center" style={{ padding: '20px 25px', borderRadius: 10 }}>
                <div className="col-md-4 col-sm-12 sm-mt-2">
                  <div className="subscribe-form sm-mt-2">
                    <form id="mc-form" className="group" style={{ margin: '5px 0px 15px 0px' }}>
                      <input
                        type="email"
                        value={name}
                        name="name"
                        className="email"
                        id="mc-email"
                        placeholder={intl.get('components.contact.nome')}
                        style={{ height: 35 }}
                        onChange={e => setName(e.currentTarget.value)}
                      />
                    </form>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 sm-mt-2">
                  <div className="subscribe-form sm-mt-2">
                    <form id="mc-form" className="group" style={{ margin: '5px 0px 15px 0px', boxShadow: '0px 0px 10px 0 rgba(117, 178, 240, 0.4)', borderRadius: 10 }}>
                      <select value={job} className="form-control" style={{ backgroundColor: '#FFF', fontSize: 15 }} onChange={e => setJob(e.currentTarget.value)}>
                        <option value="">{intl.get('components.contact.selecioneCargo')}</option>
                        <option value="gestor">{intl.get('components.contact.gestor')}</option>
                        <option value="diretor">{intl.get('components.contact.diretor')}</option>
                        <option value="colaborador">{intl.get('components.contact.colaborador')}</option>
                        <option value="outro">{intl.get('components.contact.outro')}</option>
                      </select>
                    </form>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 sm-mt-2">
                  <div className="subscribe-form sm-mt-2">
                    <form id="mc-form" className="group" style={{ margin: '5px 0px 15px 0px', boxShadow: '0px 0px 10px 0 rgba(117, 178, 240, 0.4)', borderRadius: 10 }}>
                      <select value={department} className="form-control" style={{ backgroundColor: '#FFF', fontSize: 15 }} onChange={e => setDepartment(e.currentTarget.value)}>
                        <option value="">{intl.get('components.contact.encaminharPara')}</option>
                        <option value="suporte">{intl.get('components.contact.suporte')}</option>
                        <option value="comercial">{intl.get('components.contact.comercial')}</option>
                        <option value="recursosHumanos">{intl.get('components.contact.recursosHumanos')}</option>
                        <option value="outro">{intl.get('components.contact.outro')}</option>
                      </select>
                    </form>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 sm-mt-2">
                  <div className="subscribe-form sm-mt-2">
                    <form id="mc-form" className="group" style={{ margin: '5px 0px 5px 0px' }}>
                      <input
                        type="email"
                        value={phone}
                        name="phone"
                        className="email"
                        id="mc-email"
                        placeholder={intl.get('components.contact.telefone')}
                        style={{ height: 35 }}
                        onChange={e => setPhone(e.currentTarget.value)}
                      />
                    </form>
                  </div>
                </div>

                <div className="col-md-8 col-sm-12 sm-mt-2">
                  <div className="subscribe-form sm-mt-2">
                    <form id="mc-form" className="group" style={{ margin: '5px 0px 5px 0px' }}>
                      <input
                        type="email"
                        value={email}
                        name="email"
                        className="email"
                        id="mc-email"
                        placeholder={intl.get('components.contact.email')}
                        style={{ height: 35 }}
                        onChange={e => setEmail(e.currentTarget.value)}
                      />
                      <input
                        id="formulario-contato"
                        className="btn btn-theme formulario-contato"
                        type="button"
                        name="subscribe"
                        value={intl.get('components.contact.SolicitarContato')}
                        style={{ height: 35, top: 0, right: -1 }}
                        onClick={() => handleSubmit()}
                        disabled={!name || !phone || !email}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(ContactComponent);
