import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg {...props} viewBox="0 0 93.44 76.74">
      <defs>
        <style>{'.prefix__cls-1{fill:#265f50!important}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M63 14.41a1.15 1.15 0 01-1.15-1.16v-6.8h-7.48a1.15 1.15 0 110-2.3H63a1.15 1.15 0 011.14 1.15v8A1.16 1.16 0 0163 14.41zM49.92 6.45H18.68a1.15 1.15 0 110-2.3h31.24a1.15 1.15 0 010 2.3zM21.23 57.84H1.15A1.16 1.16 0 010 56.69V5.3a1.15 1.15 0 011.15-1.15h13.24a1.15 1.15 0 110 2.3H2.31v49.08h18.92a1.16 1.16 0 010 2.31z"
          />
          <path
            className="prefix__cls-1"
            d="M28.21 16.7H5.75a1.16 1.16 0 110-2.31h22.46a1.16 1.16 0 110 2.31zM14.66 10.67a1.16 1.16 0 01-1.15-1.16V1.15a1.16 1.16 0 012.31 0v8.36a1.16 1.16 0 01-1.16 1.16zM50.16 10.67A1.16 1.16 0 0149 9.51V1.15a1.16 1.16 0 012.31 0v8.36a1.16 1.16 0 01-1.15 1.16zM14 52.3a1.15 1.15 0 01-1.15-1.16V22.2a1.15 1.15 0 112.3 0v28.94A1.15 1.15 0 0114 52.3z"
          />
          <path
            className="prefix__cls-1"
            d="M31.82 31.75h-26a1.16 1.16 0 010-2.31h26a1.16 1.16 0 010 2.31zM33.82 43.76h-28a1.16 1.16 0 010-2.31h28a1.16 1.16 0 010 2.31z"
          />
          <path
            className="prefix__cls-1"
            d="M25.28 52.3a1.15 1.15 0 01-1.15-1.16V22.2a1.15 1.15 0 112.3 0v28.94a1.15 1.15 0 01-1.15 1.16zM48.73 51.48a7.65 7.65 0 01-5.95-3.13A13 13 0 0141 45.52l-.52-.6a12.9 12.9 0 01-3-6.22 5 5 0 00-.29-1.16l-.19-.38a9.56 9.56 0 01-1-4.26V27a1.15 1.15 0 112.3 0v5.9a7.37 7.37 0 00.76 3.23l.2.38a7.32 7.32 0 01.49 1.78 10.55 10.55 0 002.47 5.1c.27.31.5.57.68.79a1.26 1.26 0 01.18.32s1.91 4.68 5.62 4.68 5.14-4.38 5.15-4.43a1 1 0 01.24-.4c.18-.22.41-.47.67-.74a10.51 10.51 0 002.64-5.08 8 8 0 01.72-2.28 9.17 9.17 0 00.68-6.59 1.15 1.15 0 112.26-.46 11.32 11.32 0 01-1 8.21 6.81 6.81 0 00-.37 1.59 12.79 12.79 0 01-3.22 6.2l-.48.52a11.1 11.1 0 01-1.5 2.71 7.11 7.11 0 01-5.76 3.05zM22.89 75.19h-.17a1.15 1.15 0 01-1-1.31l1.6-10.63a9.07 9.07 0 016-7.24h.14a1.15 1.15 0 11.75 2.18h-.14a6.75 6.75 0 00-4.49 5.39L24 74.2a1.15 1.15 0 01-1.11.99z"
          />
          <path
            className="prefix__cls-1"
            d="M33.91 56.8a1.15 1.15 0 01-.38-2.24l7.2-2.48.53-6.32a1.15 1.15 0 112.3.19L43 53a1.15 1.15 0 01-.78 1l-7.9 2.72a1.26 1.26 0 01-.41.08zM63.2 56.86a1.19 1.19 0 01-.37-.06L54.76 54a1.16 1.16 0 01-.76-1l-.6-6.5a1.15 1.15 0 011-1.25 1.14 1.14 0 011.25 1l.53 5.79 7.36 2.53a1.16 1.16 0 01-.38 2.25zM73.85 75.19a1.14 1.14 0 01-1.14-1l-1.44-10.5a6.72 6.72 0 00-4.56-5.51 1.15 1.15 0 01.73-2.18 9 9 0 016.12 7.38L75 73.88a1.14 1.14 0 01-1 1.29z"
          />
          <circle className="prefix__cls-1" cx={44.38} cy={35.65} r={1.34} />
          <circle className="prefix__cls-1" cx={53.15} cy={35.65} r={1.34} />
          <path
            className="prefix__cls-1"
            d="M53.45 18.19c-.06-.18-1.59-4.32-10.36-3.1a24.1 24.1 0 01-2.64.3 14 14 0 01-1.43 0l.07.15A1.15 1.15 0 0138.7 17c-.21.15-2.16 1.38-5.39-.21a1.15 1.15 0 111-2.07 5 5 0 002.06.56l-.53-1.08a1.16 1.16 0 01.33-1.42 1.17 1.17 0 011.46 0c.45.39 2.87.38 5.06 0 10.85-1.52 12.81 4.43 12.89 4.68l-2.21.68z"
          />
          <path
            className="prefix__cls-1"
            d="M58.4 30.65a9.37 9.37 0 01-5.4-1.38 4.11 4.11 0 01-1.73-2.2l2.23-.59s.79 2.12 5.7 1.89c.74-1.87 2.61-7.58-.43-10.63a3.55 3.55 0 00-4.65-.61 1.15 1.15 0 01-1.34-1.88 5.85 5.85 0 017.63.86c4.8 4.82.8 13.49.62 13.85a1.15 1.15 0 01-.94.66c-.61.01-1.16.03-1.69.03z"
          />
          <path
            className="prefix__cls-1"
            d="M42.81 29.69a9.89 9.89 0 01-7.52-2.86c-1.12-1.18-3.61-4.64-2.61-11.23a1.15 1.15 0 011.31-1A1.17 1.17 0 0135 16c-.22 1.45-.72 6.41 2 9.29 1.59 1.68 4 2.38 7.32 2.07 8.75-.83 9.1-4.77 9.11-4.94l2.31.05c0 .25-.25 6.12-11.21 7.16a16.34 16.34 0 01-1.72.06z"
          />
          <path
            className="prefix__cls-1"
            d="M37.77 37.14a1.31 1.31 0 01-.43-.08 1.15 1.15 0 01-.64-1.5l2.95-7.45a1.15 1.15 0 112.14.85l-2.95 7.45a1.16 1.16 0 01-1.07.73zM59.47 37.38a1.14 1.14 0 01-1.11-.85l-1.87-6.72a1.14 1.14 0 01.8-1.42 1.16 1.16 0 011.42.8l1.87 6.73a1.14 1.14 0 01-1.11 1.46zM36.13 73.79a1.15 1.15 0 01-1-.5l-3.39-4.93a6.44 6.44 0 011.67-9 6.44 6.44 0 019 1.67l3.4 4.97a1.16 1.16 0 01-1.91 1.3l-3.38-4.93a4.14 4.14 0 00-6.82 4.68L37.08 72a1.16 1.16 0 01-.3 1.61 1.21 1.21 0 01-.65.18z"
          />
          <path
            className="prefix__cls-1"
            d="M34.12 61.5a1.15 1.15 0 01-1.07-.71 8.08 8.08 0 014.88-10.73l3.77-1.28a1.15 1.15 0 01.74 2.22l-3.77 1.28a5.79 5.79 0 00-3.49 7.67 1.14 1.14 0 01-.62 1.5 1.19 1.19 0 01-.44.05zM59.26 69.44a1.16 1.16 0 01-1-1.65l3.7-7.91a5.77 5.77 0 00-3.49-7.64L54.66 51a1.15 1.15 0 11.74-2.18l3.77 1.28a8.08 8.08 0 014.88 10.73.08.08 0 000 .05l-3.72 7.93a1.16 1.16 0 01-1.07.63zM63 60.35z"
          />
          <path
            className="prefix__cls-1"
            d="M57.83 74.48a3.73 3.73 0 113.73-3.73 3.74 3.74 0 01-3.73 3.73zm0-5.16a1.43 1.43 0 101.42 1.43 1.43 1.43 0 00-1.42-1.43zM46 70.91a2.71 2.71 0 112.7-2.7 2.71 2.71 0 01-2.7 2.7zm0-3.1a.4.4 0 10.4.4.4.4 0 00-.4-.4zM37.09 76.74A2.71 2.71 0 1139.8 74a2.71 2.71 0 01-2.71 2.74zm0-3.11a.4.4 0 10.4.4.4.4 0 00-.4-.4zM48.73 62.32a1.16 1.16 0 01-.83-.32l-7.68-8a1.15 1.15 0 011.66-1.6l6.84 7.11 6.67-7a1.15 1.15 0 011.67 1.59l-7.5 7.9a1.12 1.12 0 01-.83.36zM48.64 45.37a3.75 3.75 0 01-3.75-3.75 1.16 1.16 0 012.31 0 1.44 1.44 0 002.88 0 1.16 1.16 0 012.31 0 3.76 3.76 0 01-3.75 3.75z"
          />
          <circle
            cx={77.79}
            cy={41.57}
            r={14.64}
            fill="none"
            stroke="#265f50"
            strokeMiterlimit={10}
            strokeWidth={2}
          />
          <path
            className="prefix__cls-1"
            d="M77.79 49.11A1.15 1.15 0 0176.64 48V35.19a1.16 1.16 0 012.31 0V48a1.15 1.15 0 01-1.16 1.11z"
          />
          <path
            className="prefix__cls-1"
            d="M85.33 41.57a1.15 1.15 0 01-1.15 1.16H71.41a1.16 1.16 0 010-2.31h12.77a1.15 1.15 0 011.15 1.15z"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoSvgComponent = React.memo(SvgComponent);
export default MemoSvgComponent;
