import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 60.55 54.94" {...props}>
      <defs>
        <style>
          {
            '.prefix__cls-11111{fill:none;stroke:#3f2963;stroke-miterlimit:10;stroke-width:2px}'
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-11111"
            d="M20.61 31.45H1V1h28.98M31.45 9.89v8.52"
          />
          <path
            className="prefix__cls-11111"
            d="M10.81 13.81l7.61 7.2 16-19.51M48.84 10l7.47 7.47a1.92 1.92 0 01.41 2.53 290.64 290.64 0 01-26 24.58c-10.7 8.9-14.12 8.06-14.12 8.06-1 .7-3.16 2-4.13 1s.26-3.08 1-4.1c0 0-.78-3.49 8.33-14.38A270.63 270.63 0 0146.33 9.55a1.93 1.93 0 012.51.45zM59.13 24.91a127.46 127.46 0 01-15.24 15.23M30.75 44.53L21.8 35.1"
          />
          <path
            className="prefix__cls-11111"
            d="M49.37 10.5L52.92 7c.43-.42 1.27-.27 1.88.35l4.2 4.12c.61.61.77 1.45.34 1.88l-3.58 3.55"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
