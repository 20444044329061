import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 89.19 50.91" {...props}>
      <defs>
        <style>
          {
            '.prefix__cls-11{fill:none;stroke:#3f2963;stroke-linejoin:round;stroke-width:2px}'
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-11"
            d="M55.16 28.37l-18.62.1 11.08 8.47-6.13 10.03 17.35-3.51 14.58 6.45-.36-10.63 15.13-6.06-17.57-3.06-5.22-10.19-4.98 4.08M13.6 18c2.15.83 3.56 2.23 3.56 3.82 0 2.55-3.62 4.61-8.08 4.61S1 24.41 1 21.86c0-2.19 2.68-4 6.27-4.49"
          />
          <path
            className="prefix__cls-11"
            d="M9.46 19.84S17.33-1.83 36.09 1.31C55 4.49 58.91 27.48 59.63 34"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
