import React, { Fragment } from 'react';
import { renderRoutes } from 'react-router-config';

function PageRootPage(props) {
  return (
    <Fragment>{renderRoutes(props.route.routes)}</Fragment>
  );
}

export default PageRootPage;
