import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 78.38 109.15" {...props}>
      <defs>
        <style>{'.prefix__cls-1{fill:#00a79f}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M46.69 12.5h.52a15.29 15.29 0 0115.28 15.27 1.6 1.6 0 003.2 0A18.5 18.5 0 0047.21 9.29h-.62a1.6 1.6 0 10.1 3.19z"
          />
          <path
            className="prefix__cls-1"
            d="M72.72 50.67a5.72 5.72 0 00-3 .67 5.87 5.87 0 00-5.59-5 5.76 5.76 0 00-3.21.81 5.83 5.83 0 00-8.21-3.38v-4.49a12.58 12.58 0 006.55-11v-.14a1.61 1.61 0 00-1.6-1.58 1.6 1.6 0 00-1.58 1.62v.1a9.36 9.36 0 01-3.35 7.17v-2.69a5.86 5.86 0 00-6.08-5.86 5.9 5.9 0 00-5.66 6v2.81a9.37 9.37 0 1112.9-13.51 1.6 1.6 0 102.44-2.07 12.57 12.57 0 00-19.73.69L19.78 9.74A7.21 7.21 0 007 3.25a7.2 7.2 0 006 11.17 7.24 7.24 0 005-2l17 11.2a12.85 12.85 0 00-.78 3h-20a7.21 7.21 0 100 3.2h20a12.27 12.27 0 00.78 3L18 44.09a7.21 7.21 0 00-12 3.74 7.21 7.21 0 005.61 8.52 7.33 7.33 0 001.39.15 7.12 7.12 0 004-1.19 7.23 7.23 0 002.79-8.55L36.6 35.68a12.68 12.68 0 004.4 3.76l-.07 24.09-.93-2.79a.61.61 0 00-.05-.12 6.85 6.85 0 00-6.34-4.21 3.71 3.71 0 00-3.7 3.88l.53 11.77a16.15 16.15 0 003.78 9.65 1.6 1.6 0 002.44-2.06 12.93 12.93 0 01-3-7.73l-.54-11.78a.56.56 0 01.14-.37.51.51 0 01.37-.16 3.67 3.67 0 013.37 2.2l.9 2.71a3.19 3.19 0 006.22-1l.1-30.68a2.75 2.75 0 012.57-2.77 2.7 2.7 0 012 .75 2.67 2.67 0 01.81 1.92v31.14a1.6 1.6 0 103.2 0V48.82a2.57 2.57 0 01.78-1.82 2.66 2.66 0 012-.75 2.74 2.74 0 012.57 2.77v14.86a1.6 1.6 0 103.2 0V52.09a2.67 2.67 0 012.77-2.6 2.75 2.75 0 012.57 2.77v11.62a1.6 1.6 0 003.2 0v-7.35a2.67 2.67 0 012.77-2.67 2.76 2.76 0 012.57 2.78v16.62a12.51 12.51 0 01-2.3 7.24L70.45 84a15.7 15.7 0 00-2.88 9v1.24h-22.3v-2.39a7.35 7.35 0 00-2.2-5.23l-1.37-1.49a1.6 1.6 0 10-2.35 2.16l1.4 1.52.06.07a4.14 4.14 0 011.27 3v2.43h-.38a3.2 3.2 0 00-3.2 3.2V106a3.21 3.21 0 003.2 3.2h29.46a3.21 3.21 0 003.21-3.2v-8.52a3.21 3.21 0 00-3.21-3.2h-.39V93a12.52 12.52 0 012.29-7.24l2.44-3.46a15.7 15.7 0 002.88-9.08V56.64a5.9 5.9 0 00-5.66-6zM16.38 9.42a4 4 0 11-4.17-6.14A4.65 4.65 0 0113 3.2a4 4 0 013.35 6.22zM7.21 32.26a4 4 0 114-4 4 4 0 01-4 4zm8 20.38a4 4 0 111.15-5.56 4 4 0 01-1.15 5.56zm55.96 44.84V106H41.7v-8.53z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
