import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import { statusLoader } from '../../components/loader/loaderGeneral/LoaderGeneralAction';

export function sendMessage(origin, name, job, phone, email, department, message) {
  const body = {};
  body.subject = origin === 'home' ? 'Solicitação de contato' : 'Mensagem página de contato';
  body.name_from = name;
  body.email_from = email;
  body.phone = phone;
  body.job = job;
  body.department = department;
  body.message = message;
  body.salt = 'YjM1YjQ5NjI1ZjJmMzQxYzg5Mzc3OGJiNGIzZGIzNzU=';

  return (dispatch) => {
    axios.post(`${process.env.REACT_APP_API_URL}/v1/general/send-email`, body)
      .then(() => {
        toastr.success('Obrigado!', 'Mensagem enviada com sucesso.');
        dispatch(statusLoader(false));
      })
      .catch(
        (e) => {
          if (e.response.data.data) {
            if (e.response.data.data.errors) {
              const errors = Object.values(e.response.data.data.errors);
              errors.map(err => (
                err.map(e => (
                  toastr.warning('Atenção:', e)
                ))
              ));
            } else if (e.response.data.data.message) {
              toastr.warning('Atenção:', e.response.data.data.message);
            } else {
              toastr.error('Atenção:', 'Contate o suporte.');
            }
          } else {
            toastr.error('Atenção:', 'Contate o suporte.');
          }
          dispatch(statusLoader(false));
        },
      );
  };
}
