import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 89.03 89.03" {...props}>
      <defs>
        <style>{'.prefix__cls-1{fill:#00a79f}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <g id="prefix__Calendar">
            <path
              className="prefix__cls-1"
              d="M81.61 8.9h-7.42v-3a5.94 5.94 0 10-11.87 0v3H50.45v-3a5.94 5.94 0 10-11.87 0v3H26.71v-3a5.94 5.94 0 10-11.87 0v3H7.42A7.42 7.42 0 000 16.32v59.36a7.42 7.42 0 007.42 7.42H49a1.49 1.49 0 000-3H7.42A4.47 4.47 0 013 75.68v-46h83.06V54.9a1.49 1.49 0 103 0V16.32a7.42 7.42 0 00-7.45-7.42zm-16.32-3a3 3 0 115.93 0v8.9a3 3 0 11-5.93 0zm-23.74 0a3 3 0 115.93 0v8.9a3 3 0 11-5.93 0zm-23.74 0a3 3 0 115.93 0v8.9a3 3 0 11-5.93 0zm68.25 20.81H3V16.32a4.47 4.47 0 014.45-4.45h7.42v3a5.94 5.94 0 0011.87 0v-3h11.84v3a5.94 5.94 0 0011.87 0v-3h11.87v3a5.94 5.94 0 0011.87 0v-3h7.42a4.46 4.46 0 014.45 4.45z"
            />
            <path
              className="prefix__cls-1"
              d="M41.55 40.06a3 3 0 00-3-3h-5.91a3 3 0 00-3 3v4.46a3 3 0 003 3h5.94a3 3 0 003-3zm-8.91 4.46v-4.46h5.94v4.46zM23.74 40.06a3 3 0 00-3-3h-5.9a3 3 0 00-3 3v4.46a3 3 0 003 3h5.93a3 3 0 003-3zm-8.9 4.46v-4.46h5.93v4.46zM74.19 47.48a3 3 0 003-3v-4.42a3 3 0 00-3-3h-5.93a3 3 0 00-3 3v4.46a3 3 0 003 3zm-5.93-7.42h5.93v4.46h-5.93zM41.55 53.42a3 3 0 00-3-3h-5.91a3 3 0 00-3 3v4.45a3 3 0 003 3h5.94a3 3 0 003-3zm-8.91 4.45v-4.45h5.94v4.45zM23.74 53.42a3 3 0 00-3-3h-5.9a3 3 0 00-3 3v4.45a3 3 0 003 3h5.93a3 3 0 003-3zm-8.9 4.45v-4.45h5.93v4.45zM38.58 63.81h-5.94a3 3 0 00-3 3v4.45a3 3 0 003 3h5.94a3 3 0 003-3v-4.49a3 3 0 00-3-2.96zm-5.94 7.41v-4.45h5.94v4.45zM50.45 47.48h5.94a3 3 0 003-3v-4.42a3 3 0 00-3-3h-5.94a3 3 0 00-3 3v4.46a3 3 0 003 2.96zm0-7.42h5.94v4.46h-5.94zM47.48 57.87a3 3 0 003 3 1.49 1.49 0 000-3v-4.45h5.94a1.49 1.49 0 000-3h-5.97a3 3 0 00-3 3zM20.77 63.81h-5.93a3 3 0 00-3 3v4.45a3 3 0 003 3h5.93a3 3 0 003-3v-4.49a3 3 0 00-3-2.96zm-5.93 7.41v-4.45h5.93v4.45z"
            />
            <path
              className="prefix__cls-1"
              d="M69.74 50.45A19.29 19.29 0 1089 69.74a19.29 19.29 0 00-19.26-19.29zm0 35.61a16.32 16.32 0 1116.32-16.32 16.32 16.32 0 01-16.32 16.32z"
            />
            <path
              className="prefix__cls-1"
              d="M78.31 63.52L66.77 75.06l-5.24-5.24a1.48 1.48 0 00-2.1 2.09l6.29 6.3a1.48 1.48 0 002.1 0l12.59-12.59a1.48 1.48 0 000-2.1 1.5 1.5 0 00-2.1 0z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
