import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';

// Componentes
import Logo from '../icons/Logo';

function Footer() {
  return (
    <footer className="footer footer-1 pos-r" style={{ marginTop: 0, paddingTop: 0 }}>
      <div className="primary-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-logo" style={{ width: '100%', padding: '0px 30px' }}>
                <Logo />
              </div>
              <div className="col-lg-12 col-md-12" style={{ fontSize: 12, textAlign: 'center' }}>
                {intl.get('components.layout.footer.todosDireitosReservados')}
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pl-md-5 sm-mt-5 footer-list justify-content-between d-flex">
              <ul className="list-unstyled w-100">
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <li>
                    <div style={{ fontSize: 15, color: '#835BDB', marginBottom: 10 }}>
                      {intl.get('components.layout.footer.home')}
                    </div>
                  </li>
                </Link>
                <Link to="/how" style={{ textDecoration: 'none' }}>
                  <li>
                    <div style={{ fontSize: 15, color: '#835BDB', marginBottom: 10 }}>
                      {intl.get('components.layout.footer.comoFunciona')}
                    </div>
                  </li>
                </Link>
              </ul>
              <ul className="list-unstyled w-100">
                <Link to="/contact" style={{ textDecoration: 'none' }}>
                  <li>
                    <div style={{ fontSize: 15, color: '#835BDB', marginBottom: 10 }}>
                      {intl.get('components.layout.footer.contato')}
                    </div>
                  </li>
                </Link>
                <Link to="/faq" style={{ textDecoration: 'none' }}>
                  <li>
                    <div style={{ fontSize: 15, color: '#835BDB', marginBottom: 10 }}>
                      {intl.get('components.layout.footer.faq')}
                    </div>
                  </li>
                </Link>
                <Link to="/contact" style={{ textDecoration: 'none' }}>
                  <li>
                    <div style={{ fontSize: 15, color: '#835BDB', marginBottom: 10 }}>
                      {intl.get('components.layout.footer.trabalheConosco')}
                    </div>
                  </li>
                </Link>
              </ul>
            </div>
            <div className="col-lg-4 col-md-12 md-mt-5">
              <ul className="media-icon list-unstyled">
                <li style={{ marginBottom: 10 }}>
                  <div style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {intl.get('components.layout.footer.nome')}
                  </div>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <div style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {intl.get('components.layout.footer.endereco')}
                  </div>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <div style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {intl.get('components.layout.footer.cidade')}
                  </div>
                </li>
                <li style={{ marginBottom: 0 }}>
                  <div style={{ fontSize: 10 }}>
                    {intl.get('components.layout.footer.frase1')}
                    {intl.get('components.layout.footer.frase2')}
                  </div>
                </li>
              </ul>

              <div className="col-lg-12 col-md-12 text-lg-right md-mt-3" style={{ marginTop: 15, padding: 0 }}>
                <div className="footer-social">
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
                    <div style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
                      <i className="fab fa-facebook" style={{ fontSize: 30 }} /> Facebook
                    </div>
                    <div style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}>
                      <i className="fab fa-linkedin" style={{ fontSize: 30 }} /> LinkedIn
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default memo(Footer);
