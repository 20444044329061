import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';

function Container(props) {
  return (
    <Content spacings={props.spacings} padding={props.padding}>
      <Grid
        item
        container
        justify={props.justify || 'flex-start'}
        alignItems={props.alignItems || 'flex-start'}
        bottom={props.bottom || '10px'}
        spacing={props.spacings ? 2 : 0}
        xs={props.xs || 12}
        sm={props.sm || 12}
        md={props.md || 12}
        lg={props.lg || 12}
        xl={props.xl || 12}
      >
        {props.children}
      </Grid>
    </Content>
  );
}

export default Container;

// ==================================================
// Estilos StarGrid
// ==================================================
const Content = styled.div`
  width: 100%;
  margin-right: ${props => (props.spacings ? '-15px' : '0px')};
  padding: ${props => (props.padding ? '0px 15px 0px 15px' : '0px')};
  margin-bottom: ${props => (props.bottom)};;
`;
