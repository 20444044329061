import React, { useEffect, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';

// Componentes
import Layout from '../../components/layout/Layout';

// Actions
import { sendMessage } from './ContactAction';
import { statusLoader } from '../../components/loader/loaderGeneral/LoaderGeneralAction';

function ContactPage() {
  const dispatch = useDispatch();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [job, setJob] = useState();
  const [message, setMessage] = useState();
  const [department, setDepartment] = useState();

  const handleSubmit = () => {
    dispatch(statusLoader(true));
    dispatch(sendMessage('contact', name, job, phone, email, department, message));
    setName('');
    setEmail('');
    setPhone('');
    setJob('');
    setDepartment('');
    setMessage('');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="page-content" style={{ marginTop: 75 }}>

        <section className="contact-1">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-8 col-md-12 mr-auto">
                <div className="section-title">
                  <div className="title-effect">
                    <div className="bar bar-top" />
                    <div className="bar bar-right" />
                    <div className="bar bar-bottom" />
                    <div className="bar bar-left" />
                  </div>
                  <h6>{intl.get('modules.contact.contato')}</h6>
                  <h2 style={{ textTransform: 'none' }}>
                    {intl.get('modules.contact.entreEmContatoConosco')}
                  </h2>
                  <p>{intl.get('modules.contact.envieSuaMensagemOuSoliciteSuaDemonstracao')}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <form method="post" action="http://webit.com/sopno/html/ltr/php/contact.php">
                  <div className="messages" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{intl.get('modules.contact.nomeCompleto')}</label>
                        <input
                          id="form_name"
                          value={name}
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder={intl.get('modules.contact.digiteSeuNome')}
                          required="required"
                          data-error={intl.get('modules.contact.nomeObrigatorio')}
                          onChange={e => setName(e.currentTarget.value)}
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{intl.get('modules.contact.cargo')}</label>
                        <select value={job} className="form-control" style={{ backgroundColor: '#fafaff', fontSize: 13, fontWeight: 400 }} onChange={e => setJob(e.currentTarget.value)}>
                          <option value="">{intl.get('modules.contact.selecioneSeuCargo')}</option>
                          <option value="gestor">{intl.get('modules.contact.gestor')}</option>
                          <option value="diretor">{intl.get('modules.contact.diretor')}</option>
                          <option value="colaborador">{intl.get('modules.contact.colaborador')}</option>
                          <option value="outro">{intl.get('modules.contact.outro')}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{intl.get('modules.contact.email')}</label>
                        <input
                          id="form_email"
                          value={email}
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder={intl.get('modules.contact.digiteSeuEmail')}
                          required="required"
                          data-error={intl.get('modules.contact.emailObrigatorio')}
                          onChange={e => setEmail(e.currentTarget.value)}
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{intl.get('modules.contact.telefone')}</label>
                        <input
                          id="form_phone"
                          value={phone}
                          type="tel"
                          name="phone"
                          className="form-control"
                          placeholder={intl.get('modules.contact.digiteSeuTelefone')}
                          required="required"
                          data-error={intl.get('modules.contact.telefoneObrigatorio')}
                          onChange={e => setPhone(e.currentTarget.value)}
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{intl.get('modules.contact.encaminharPara')}</label>
                        <select value={department} className="form-control" style={{ backgroundColor: '#fafaff', fontSize: 13, fontWeight: 400 }} onChange={e => setDepartment(e.currentTarget.value)}>
                          <option value="">{intl.get('modules.contact.selecioneSetor')}</option>
                          <option value="suporte">{intl.get('modules.contact.suporte')}</option>
                          <option value="comercial">{intl.get('modules.contact.comercial')}</option>
                          <option value="recursosHumanos">{intl.get('modules.contact.recursosHumanos')}</option>
                          <option value="outro">{intl.get('modules.contact.outro')}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>{intl.get('modules.contact.mensagem')}</label>
                        <textarea
                          id="form_message"
                          value={message}
                          name="message"
                          className="form-control"
                          placeholder={intl.get('modules.contact.digiteSuaMensagem')}
                          rows="4"
                          onChange={e => setMessage(e.currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mt-2">
                      <button
                        id="formulario-contato"
                        className="btn btn-theme btn-circle formulario-contato"
                        data-text={intl.get('modules.contact.enviarMensagem')}
                        onClick={() => handleSubmit()}
                        disabled={!name || !phone || !email}
                      >
                        <span>{intl.get('modules.contact.enviarMensagem')}</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-info p-0 z-index-1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-12">
                <div className="contact-media" style={{ padding: '50px 30px 25px 30px' }}>
                  <i className="flaticon-paper-plane" /><span>{intl.get('modules.contact.endereco')}</span>
                  <p>{intl.get('modules.contact.enderecoCompleto')}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 md-mt-5">
                <div className="contact-media">
                  <i className="flaticon-email" />
                  <span>{intl.get('modules.contact.email')}</span>
                  <a href="mailto:contato@stargrid.pro"> contato@stargrid.pro</a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 md-mt-5">
                <div className="contact-media">
                  <i className="flaticon-social-media" />
                  <span>{intl.get('modules.contact.telefone')}</span>
                  <img className="img-fluid" src="images/whatsapp-icon.png" style={{ width: 24, marginRight: 4 }} alt="" />
                  <a href="https://api.whatsapp.com/send?phone=555198501219" target="blank">(51) 99850.1219</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="o-hidden p-0 custom-mt-10 z-index-0">
          <div className="container-fluid p-0" style={{ marginBottom: 20 }}>
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="map iframe-h-2">
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.203784695034!2d-51.173830885114455!3d-30.059692638946068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951977fd79a847e5%3A0xa0f279b1e3f4bf0c!2sTecnopuc!5e0!3m2!1spt-BR!2sin!4v1586281088466!5m2!1spt-BR!2sin"
                    allowFullScreen=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </Layout>
  );
}

export default memo(ContactPage);
