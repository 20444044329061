import React, { useEffect, memo } from 'react';
import intl from 'react-intl-universal';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

// Componentes
import Layout from '../../components/layout/Layout';
import Contact from '../../components/contact/Contact';
import Asset18 from '../../components/icons/Asset_18';
import Asset4 from '../../components/icons/Asset_4';
import Asset30 from '../../components/icons/Asset_30';
import Asset31 from '../../components/icons/Asset_31';
import Asset32 from '../../components/icons/Asset_32';
import Asset33 from '../../components/icons/Asset_33';

function HowPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <section id="home" className="fullscreen-banner banner p-0 bg-contain bg-pos-rt" style={{ backgroundImage: 'url(images/bg/Asset_2.png)', backgroundRepeat: 'no-repeat', backgroundPosition: '200% -200px', marginBottom: -300 }}>
        <div className="spinner-eff">
          <div className="spinner-circle circle-1" style={{ backgroundColor: '#f4eefc' }} />
          <div className="spinner-circle circle-2" style={{ backgroundColor: '#f4eefc' }} />
        </div>
        <div className="align-center pt-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-12 order-lg-1">
                <Asset18 />
              </div>
              <div className="col-lg-5 col-md-12 order-lg-1 md-mt-5">
                <h1 style={{ color: '#835BDB', textTransform: 'none', lineHeight: '1', fontSize: 28, marginBottom: '16px !important' }}>
                  {intl.get('modules.how.dimensionamentoDe')}<br />
                  {intl.get('modules.how.forcaDeTrabalho')}
                </h1>
                <p style={{ color: '#58595b', fontSize: 15, marginTop: 12, marginBottom: 3 }}>
                  <Icon icon={faCheck} />
                  {intl.get('modules.how.geracaoDeEscalasAutomaticas')}
                </p>
                <p style={{ color: '#58595b', fontSize: 15, marginBottom: 3 }}>
                  <Icon icon={faCheck} />
                  {intl.get('modules.how.notificoesNoAppEmTempoReal')}
                </p>
                <p style={{ color: '#58595b', fontSize: 15, marginBottom: 3 }}>
                  <Icon icon={faCheck} />
                  {intl.get('modules.how.otimizacaoDoTempo')}
                </p>
                <h1 style={{ color: '#835BDB', textTransform: 'none', lineHeight: '1', fontSize: 28, marginTop: 20, marginBottom: '5px !important' }}>
                  {intl.get('modules.how.gestaoDeTrocas')}
                </h1>
                <p style={{ color: '#58595b', fontSize: 15, marginTop: 12, marginBottom: 3 }}>
                  <Icon icon={faCheck} />
                  {intl.get('modules.how.facilitacaoDaGestaoDeTrocasDiariasFolgasFaltas')}
                </p>
                <p style={{ color: '#58595b', fontSize: 15, marginBottom: 3 }}>
                  <Icon icon={faCheck} />
                  {intl.get('modules.how.agilidadeNaNegociacaoDeTrocasEntreColaboradores')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="page-content" style={{ marginTop: 75 }}>
        <section id="service" className="service pos-r o-hidden" style={{ backgroundImage: 'url(images/bg/Asset_2.png)', backgroundRepeat: 'no-repeat', backgroundPosition: '-100% 80%', backgroundSize: '80% 80%' }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="col-lg-12 col-md-12 order-lg-1 md-mt-5">
                  <h1 className="mb-4 wow fadeInUp" data-wow-duration="1.5s" style={{ color: '#2A2E43', fontSize: 40, textTransform: 'none', textAlign: 'left', lineHeight: '1', marginLeft: 75 }}>
                    {intl.get('modules.how.organizacao')}
                  </h1>
                  <h1 className="mb-4 wow fadeInUp" data-wow-duration="1.5s" style={{ color: '#835BDB', fontSize: 40, textTransform: 'none', textAlign: 'left', lineHeight: '1', marginTop: -20, marginLeft: 75 }}>
                    {intl.get('modules.how.passoAPasso')}
                  </h1>
                </div>
                <div>
                  <Asset4 />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 md-mt-5">
                <div className="row">
                  <div className="col-lg-6 col-md-6" style={{ marginBottom: 15 }}>
                    <div className="featured-item style-3">
                      <div className="featured-icon">
                        <Asset30 style={{ height: 60 }} />
                      </div>
                      <div className="featured-title">
                        <h5>{intl.get('modules.how.passo1')}</h5>
                      </div>
                      <div className="featured-desc">
                        <p>{intl.get('modules.how.colaboradorEscolheSuasFolgasPreferenciais')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="featured-item style-3">
                      <div className="featured-icon">
                        <Asset33 style={{ height: 60 }} />
                      </div>
                      <div className="featured-title">
                        <h5>{intl.get('modules.how.passo2')}</h5>
                      </div>
                      <div className="featured-desc">
                        <p>{intl.get('modules.how.gestorConfiguraAsRegrasDeCadaSetor')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 sm-mt-3">
                    <div className="featured-item style-3">
                      <div className="featured-icon">
                        <Asset31 style={{ height: 60 }} />
                      </div>
                      <div className="featured-title">
                        <h5>{intl.get('modules.how.passo3')}</h5>
                      </div>
                      <div className="featured-desc">
                        <p>{intl.get('modules.how.gestorAjustaAprovaGridCriadoAutomaticamente')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="featured-item style-3">
                      <div className="featured-icon">
                        <Asset32 style={{ height: 60 }} />
                      </div>
                      <div className="featured-title">
                        <h5>{intl.get('modules.how.passo4')}</h5>
                      </div>
                      <div className="featured-desc">
                        <p>{intl.get('modules.how.colaboradorRecebeSeuGridDeTrabalhoNoCelularPermitindoTrocas')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pos-r o-hidden" style={{ padding: 0 }}>
          <canvas id="canvas" />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="timeline-wrap">
                  <ul className="timeline timeline--first list-unstyled">
                    <li className="timeline-title">
                      <h2>
                        <div style={{ color: '#835BDB', fontSize: 13, textTransform: 'none' }}>{intl.get('modules.how.estimativaDe')}</div>
                        <div style={{ color: '#58595b' }}>{intl.get('modules.how.impactoFinanceiro')}</div>
                      </h2>
                    </li>
                    <li className="timeline-inner timeline-left">
                      <div className="timeline-content" style={{ textAlign: 'center' }}>
                        <h3 style={{ color: '#835BDB', fontSize: 25 }}>{intl.get('modules.how.absenteismo')}</h3>
                        <p style={{ color: '#58595b', fontSize: 18 }}>
                          {intl.get('modules.how.reducaoDe10NasFaltas')}<br />
                          {intl.get('modules.how.5NosAfastamentos')}
                        </p>
                      </div>
                    </li>
                    <li className="timeline-inner timeline-right">
                      <div className="timeline-content" style={{ textAlign: 'center' }}>
                        <h3 style={{ color: '#835BDB', fontSize: 25 }}>{intl.get('modules.how.horasExtras')}</h3>
                        <p style={{ color: '#58595b', fontSize: 18 }}>{intl.get('modules.how.5A15DeReducao')}</p>
                      </div>
                    </li>
                    <li className="timeline-inner timeline-left">
                      <div className="timeline-content" style={{ textAlign: 'center' }}>
                        <h3 style={{ color: '#835BDB', fontSize: 25 }}>{intl.get('modules.how.passivosJudiciais')}</h3>
                        <p style={{ color: '#58595b', fontSize: 18 }}>
                          {intl.get('modules.how.2A15DeReducao')}<br />
                          ({intl.get('modules.how.incluindoAcoesDePacientes')})
                        </p>
                      </div>
                    </li>
                    <li className="timeline-inner timeline-right">
                      <div className="timeline-content" style={{ textAlign: 'center' }}>
                        <h3 style={{ color: '#835BDB', fontSize: 25 }}>{intl.get('modules.how.turnover')}</h3>
                        <p style={{ color: '#58595b', fontSize: 18 }}>
                          {intl.get('modules.how.2A10')}<br />
                          ({intl.get('modules.how.reduzindoAindaCustosDeTreinamento')})
                        </p>
                      </div>
                    </li>
                    <li className="timeline-inner timeline-left">
                      <div className="timeline-content" style={{ textAlign: 'center' }}>
                        <h3 style={{ color: '#835BDB', fontSize: 25, textTransform: 'none' }}>{intl.get('modules.how.custoComGestao')}</h3>
                        <p style={{ color: '#58595b', fontSize: 18 }}>{intl.get('modules.how.25A35DeReducao')}</p>
                      </div>
                    </li>
                    <li className="timeline-inner timeline-right">
                      <div className="timeline-content" style={{ textAlign: 'center' }}>
                        <h3 style={{ color: '#835BDB', fontSize: 25, textTransform: 'none' }}>{intl.get('modules.how.custoComFolha')}</h3>
                        <p style={{ color: '#58595b', fontSize: 18 }}>{intl.get('modules.how.0A5DeReducao')}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Contact />

      </div>
    </Layout>
  );
}

export default memo(HowPage);

// ==================================================
// Estilos StarGrid
// ==================================================
const Icon = styled(FontAwesomeIcon)`
  font-size: 15px;
  margin-right: 5px;
  margin-bottom: -2px;
`;
