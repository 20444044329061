import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

// Componentes
import InternationalizationReducer from '../components/internationalization/InternationalizationReducer';
import LoaderGeneralReducer from '../components/loader/loaderGeneral/LoaderGeneralReducer';
import ModalOpenSiteReducer from '../components/modal/modalOpenSite/ModalOpenSiteReducer';

const appReducers = combineReducers({
  toastr: toastrReducer,
  internationalizationReducer: InternationalizationReducer,
  loaderGeneralReducer: LoaderGeneralReducer,
  modalOpenSiteReducer: ModalOpenSiteReducer,
});

export default appReducers;
