import React, { useEffect, memo } from 'react';
import mixpanel from 'mixpanel-browser';
import intl from 'react-intl-universal';

// Componentes
import Layout from '../../components/layout/Layout';
import Contact from '../../components/contact/Contact';
import Asset3 from '../../components/icons/Asset_3';
import Asset6 from '../../components/icons/Asset_6';
import Asset7 from '../../components/icons/Asset_7';
import Asset8 from '../../components/icons/Asset_8';
import Asset9 from '../../components/icons/Asset_9';
import Asset22 from '../../components/icons/Asset_22';
import Asset11 from '../../components/icons/Asset_11';
import Asset12 from '../../components/icons/Asset_12';
import Asset13 from '../../components/icons/Asset_13';
import Asset14 from '../../components/icons/Asset_14';
import Asset18 from '../../components/icons/Asset_18';
import Asset15 from '../../components/icons/Asset_15';
import Asset16 from '../../components/icons/Asset_16';
import Asset17 from '../../components/icons/Asset_17';
import Asset19 from '../../components/icons/Asset_19';
import Asset20 from '../../components/icons/Asset_20';

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <section id="home" className="fullscreen-banner banner p-0 bg-contain bg-pos-rt" style={{ backgroundImage: 'url(images/bg/Asset_2.png)', backgroundRepeat: 'no-repeat', backgroundPosition: '200% -200px', marginBottom: -150 }}>
        <div className="spinner-eff">
          <div className="spinner-circle circle-1" style={{ backgroundColor: '#f4eefc' }} />
          <div className="spinner-circle circle-2" style={{ backgroundColor: '#f4eefc' }} />
        </div>
        <div className="align-center pt-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 order-lg-1 md-mt-5">
                <h1 className="mb-4 wow fadeInUp" data-wow-duration="1.5s" style={{ color: '#243e64', textTransform: 'none', lineHeight: '1' }}>
                  {intl.get('modules.home.gestaoDeEscalasDeTrabalhoAgoraMuitoMaisSimples')}
                </h1>
                <p className="lead mb-4 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s" style={{ color: '#58595b', fontSize: 15, fontWeight: 'bold', lineHeight: '1.5' }}>
                  {intl.get('modules.home.todaEficienciaDaInteligenciaArtificial')}
                </p>
                <a href="https://contato.stargrid.pro/demonstracao" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} onClick={() => mixpanel.track('SITE/OLD/FORM/OPEN')}>
                  <div className="btn btn-theme fadeInUp" data-wow-duration="3s" data-wow-delay="0.5s" data-text="Solicite demonstração">
                    <span>{intl.get('modules.home.soliciteDemonstracao')}</span>
                  </div>
                </a>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-1">
                <img className="img-center wow jackInTheBox" data-wow-duration="3s" src="images/banner/mockup.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="page-content">
        <section className="p-0 text-center wow fadeInUp" data-wow-duration="2s" style={{ backgroundImage: 'url(images/bg/Asset_4.png)', backgroundRepeat: 'no-repeat', backgroundPosition: '-255px -0px', backgroundSize: '80% 100%' }}>
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 order-lg-1">
                <Asset3 style={{ width: '75%' }} />
              </div>
              <div className="col-lg-6 col-md-12 order-lg-1 md-mt-5">
                <h1 className="mb-4 wow fadeInUp" data-wow-duration="1.5s" style={{ color: '#243e64', fontSize: 50, textTransform: 'none', textAlign: 'left', lineHeight: '1' }}>
                  {intl.get('modules.home.inteligenciaArtificialParaSimplificarSuaRotina')}
                </h1>
                <p className="lead mb-4 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s" style={{ color: '#58595b', fontSize: 15, textAlign: 'left', lineHeight: '1.5', width: '60%' }}>
                  {intl.get('modules.home.aCriacaoAutomaticaDeEscalas')}
                  <br /><br />
                  {intl.get('modules.home.vejaQueStarGridFazParaVoce')}
                </p>
              </div>
            </div>
            <div className="row custom-mt-10 z-index-1 md-mt-0" style={{ marginTop: 0 }}>
              <div className="row col-md-12">
                <div className="item col-md-3">
                  <div className="featured-item style-2" style={{ height: 320 }}>
                    <div className="featured-icon">
                      <Asset6 style={{ height: 80 }} />
                    </div>
                    <div className="featured-title">
                      <h5 style={{ color: '#243e64' }}>{intl.get('modules.home.integra')}</h5>
                    </div>
                    <div className="featured-desc">
                      <p style={{ fontSize: 13, lineHeight: 1.4 }}>{intl.get('modules.home.facilitaIntegraçãoComOutrosSistemas')}</p>
                    </div>
                  </div>
                </div>
                <div className="item col-md-3">
                  <div className="featured-item style-2" style={{ height: 320 }}>
                    <div className="featured-icon">
                      <Asset7 style={{ height: 80 }} />
                    </div>
                    <div className="featured-title">
                      <h5 style={{ color: '#243e64' }}>{intl.get('modules.home.economiza')}</h5>
                    </div>
                    <div className="featured-desc">
                      <p style={{ fontSize: 13, lineHeight: 1.4 }}>{intl.get('modules.home.trazIndicadoresFundamentais')}</p>
                    </div>
                  </div>
                </div>
                <div className="item col-md-3">
                  <div className="featured-item style-2" style={{ height: 320 }}>
                    <div className="featured-icon">
                      <Asset8 style={{ height: 80 }} />
                    </div>
                    <div className="featured-title">
                      <h5 style={{ color: '#243e64' }}>{intl.get('modules.home.automatiza')}</h5>
                    </div>
                    <div className="featured-desc">
                      <p style={{ fontSize: 13, lineHeight: 1.4 }}>{intl.get('modules.home.utilizaInteligenciaArtificial')}</p>
                    </div>
                  </div>
                </div>
                <div className="item col-md-3">
                  <div className="featured-item style-2" style={{ height: 320 }}>
                    <div className="featured-icon">
                      <Asset9 style={{ height: 80 }} />
                    </div>
                    <div className="featured-title">
                      <h5 style={{ color: '#243e64' }}>{intl.get('modules.home.flexibiliza')}</h5>
                    </div>
                    <div className="featured-desc">
                      <p style={{ fontSize: 13, lineHeight: 1.4 }}>{intl.get('modules.home.permiteUmaConfiguracaoPersonalizada')}</p>
                    </div>
                  </div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 50, marginTop: 20 }}>
                  <a href="https://contato.stargrid.pro/demonstracao" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} onClick={() => mixpanel.track('SITE/OLD/FORM/OPEN')}>
                    <div className="btn btn-theme fadeInUp" data-wow-duration="3s" data-wow-delay="0.5s" data-text={intl.get('modules.home.soliciteDemonstracao')}>
                      <span>{intl.get('modules.home.soliciteDemonstracao')}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="text-center pos-r">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10 ml-auto mr-auto">
                <div className="section-title">
                  <div className="title-effect">
                    <div className="bar bar-top" />
                    <div className="bar bar-right" />
                    <div className="bar bar-bottom" />
                    <div className="bar bar-left" />
                  </div>
                  <h6 style={{ color: '#243e64' }}>{intl.get('modules.home.comoFunciona')}</h6>
                  <h2 className="title" style={{ color: '#243e64' }}>{intl.get('modules.home.tresPassosParaAutomatizarSuasEscalas')}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div id="svg-container">
                <svg id="svgC" width="100%" height="100%" viewBox="0 0 620 120" preserveAspectRatio="xMidYMid meet" />
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="work-process">
                  <div className="box-loader"> <span />
                    <span />
                    <span />
                  </div>
                  <div className="step-num-box">
                    <div className="step-icon" style={{ border: 'solid 2px #265f50' }}>
                      <Asset11 style={{ width: 60, height: 60 }} />
                    </div>
                    <div className="step-num" style={{ color: '#265f50' }}>01</div>
                  </div>
                  <div className="step-desc">
                    <h4 style={{ color: '#243e64' }}>{intl.get('modules.home.parametrize')}</h4>
                    <p className="mb-0">{intl.get('modules.home.parametrizeCOMO')}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 md-mt-5">
                <div className="work-process">
                  <div className="box-loader"> <span />
                    <span />
                    <span />
                  </div>
                  <div className="step-num-box">
                    <div className="step-icon" style={{ border: 'solid 2px #265f50' }}>
                      <Asset12 style={{ width: 60, height: 60 }} />
                    </div>
                    <div className="step-num" style={{ color: '#265f50' }}>02</div>
                  </div>
                  <div className="step-desc">
                    <h4 style={{ color: '#243e64' }}>{intl.get('modules.home.configure')}</h4>
                    <p className="mb-0">{intl.get('modules.home.configureCOMO')}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 md-mt-5">
                <div className="work-process">
                  <div className="step-num-box">
                    <div className="step-icon" style={{ border: 'solid 2px #265f50' }}>
                      <Asset13 style={{ width: 60, height: 60 }} />
                    </div>
                    <div className="step-num" style={{ color: '#265f50' }}>03</div>
                  </div>
                  <div className="step-desc">
                    <h4 style={{ color: '#243e64' }}>{intl.get('modules.home.aproveite')}</h4>
                    <p className="mb-0">{intl.get('modules.home.aproveiteCOMO')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="p-0 text-center wow fadeInUp" data-wow-duration="2s" style={{ marginBottom: 15 }}>
          <div className="container-fluid" style={{ padding: '0px 5%' }}>
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-12 order-lg-1" style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 2 }}>
                  <Asset14 />
                </div>
                <div style={{ flex: 3, display: 'flex', flexDirection: 'column', paddingTop: 20 }}>
                  <h1 className="mb-4 wow fadeInUp" data-wow-duration="1.5s" style={{ color: '#243e64', fontSize: 32, textTransform: 'none', textAlign: 'left', lineHeight: '1' }}>
                    {intl.get('modules.home.paraO')}<br />
                    {intl.get('modules.home.Colaborador')}
                  </h1>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                    <div style={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '12px' }}>
                      <Asset15 style={{ height: 50 }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 5 }}>
                      <div style={{ fontSize: 18, fontWeight: 500, color: '#243e64' }}>{intl.get('modules.home.agilidade')}</div>
                      <div style={{ fontSize: 13, textAlign: 'left', color: '#243e64' }}>{intl.get('modules.home.colaboradorSolicitaFolgasNoApp')}</div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                    <div style={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '12px' }}>
                      <Asset16 style={{ height: 50 }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 5 }}>
                      <div style={{ fontSize: 18, fontWeight: 500, color: '#243e64' }}>{intl.get('modules.home.colaboracao')}</div>
                      <div style={{ fontSize: 13, textAlign: 'left', color: '#243e64' }}>{intl.get('modules.home.trocaDeFolgasFacilitadasEntreOsColaboradoresDoSetor')}</div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                    <div style={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '12px' }}>
                      <Asset17 style={{ height: 50 }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 5 }}>
                      <div style={{ fontSize: 18, fontWeight: 500, color: '#243e64' }}>{intl.get('modules.home.satisfacao')}</div>
                      <div style={{ fontSize: 13, textAlign: 'left', color: '#243e64' }}>{intl.get('modules.home.maiorAtendimentoDasFolgasPreferenciais')}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 order-lg-1 md-mt-5" style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 3 }}>
                  <Asset18 />
                </div>
                <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                  <h1 className="mb-4 wow fadeInUp" data-wow-duration="1.5s" style={{ color: '#243e64', fontSize: 32, textTransform: 'none', textAlign: 'left', lineHeight: '1' }}>
                    {intl.get('modules.home.paraO')}<br />
                    {intl.get('modules.home.Gestor')}
                  </h1>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                    <div style={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '12px' }}>
                      <Asset19 style={{ height: 50 }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 5 }}>
                      <div style={{ fontSize: 18, fontWeight: 500, color: '#243e64' }}>{intl.get('modules.home.praticidade')}</div>
                      <div style={{ fontSize: 13, textAlign: 'left', color: '#243e64' }}>{intl.get('modules.home.criacaoOrganizacaoDeGridsDeTrabalhoDeFormaFacilRapida')}</div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                    <div style={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '12px' }}>
                      <Asset20 style={{ height: 50 }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 5 }}>
                      <div style={{ fontSize: 18, fontWeight: 500, color: '#243e64' }}>{intl.get('modules.home.controle')}</div>
                      <div style={{ fontSize: 13, textAlign: 'left', color: '#243e64' }}>{intl.get('modules.home.gestaoAutomatizadaDeTrocasAnaliseDasSolicitacoesDeFolgasNaPalmaDaSuaMao')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="pos-r bg-contain bg-pos-l py-10" style={{ backgroundImage: 'url(images/bg/Asset_21.png)', backgroundRepeat: 'no-repeat', backgroundPosition: 'top right' }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-12 image-column p-0 pr-lg-5" style={{ display: 'flex', justifyContent: 'center' }}>
                <Asset22 style={{ width: '100%', height: 450 }} />
              </div>
              <div className="col-lg-5 ml-auto col-md-12 md-mt-5">
                <div className="section-title mb-4">
                  <div className="title-effect">
                    <div className="bar bar-top" />
                    <div className="bar bar-right" />
                    <div className="bar bar-bottom" />
                    <div className="bar bar-left" />
                  </div>
                  <h6 style={{ color: '#243e64' }}>{intl.get('modules.home.quemSomos')}</h6>
                  <h2 style={{ color: '#58595b', fontSize: 15, textAlign: 'left', lineHeight: '1.5', textTransform: 'none' }}>
                    {intl.get('modules.home.quemSomosTexto1')}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="grey-bg pos-r o-hidden" style={{ backgroundImage: 'url(images/pattern/01.png)', backgroundRepeat: 'no-repeat' }}>
          <div className="wave-shape">
            <img className="img-fluid" src="images/bg/03.png" alt="" />
          </div>
          <div className="container">

            <div className="row text-center">
              <div className="col-lg-8 col-md-12 ml-auto mr-auto">
                <div className="section-title">
                  <div className="title-effect">
                    <div className="bar bar-top" />
                    <div className="bar bar-right" />
                    <div className="bar bar-bottom" />
                    <div className="bar bar-left" />
                  </div>
                  <h6 style={{ color: '#243e64' }}>{intl.get('modules.home.Depoimentos')}</h6>
                  <h2 className="title" style={{ color: '#243e64', textTransform: 'none' }}>
                    {intl.get('modules.home.oQueEstaoFalandoDaGente')}
                  </h2>
                  <h2 style={{ color: '#58595b', fontSize: 15, textAlign: 'center', lineHeight: '1.5', textTransform: 'none' }}>
                    {intl.get('modules.home.maisDe20000PessoasUsamConfiamNaStarGrid')}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div id="testimonial-1" className="testimonial-carousel carousel slide" data-ride="carousel" data-interval="10000">
                  <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12">
                      <div className="carousel-inner">

                        <div className="carousel-item active">
                          <div className="testimonial style-1">
                            <div className="testimonial-content">
                              <div className="testimonial-quote"><i className="la la-quote-left" style={{ color: '#dbcde6' }} />
                              </div>
                              <p>{intl.get('modules.home.carlosKleinTEXTO')}</p>
                              <div className="testimonial-caption">
                                <h5>CARLOS KLEIN</h5>
                                <label>{intl.get('modules.home.carlosKleinCARGO')}</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="carousel-item">
                          <div className="testimonial style-1">
                            <div className="testimonial-content">
                              <div className="testimonial-quote"><i className="la la-quote-left" style={{ color: '#dbcde6' }} />
                              </div>
                              <p>{intl.get('modules.home.evandroLuisMoraesTEXTO')}</p>
                              <div className="testimonial-caption">
                                <h5>EVANDRO LUIS MORAES</h5>
                                <label>{intl.get('modules.home.evandroLuisMoraesCARGO')}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="testimonial style-1">
                            <div className="testimonial-content">
                              <div className="testimonial-quote"><i className="la la-quote-left" style={{ color: '#dbcde6' }} />
                              </div>
                              <p style={{ fontSize: 11, lineHeight: '1.5' }}>
                                {intl.get('modules.home.guilhermeAgustinLouzadaPeredaTEXTO1')}
                                <br /><br />
                                {intl.get('modules.home.guilhermeAgustinLouzadaPeredaTEXTO2')}
                              </p>
                              <div className="testimonial-caption">
                                <h5>GUILHERME AGUSTIN LOUZADA PEREDA</h5>
                                <label>{intl.get('modules.home.guilhermeAgustinLouzadaPeredaCARGO')}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12 md-mt-5">
                      <div className="controls">
                        <div className="anti-01-holder">
                          <div className="anti-01" />
                        </div>
                        <div className="anti-02-holder">
                          <div className="anti-02" />
                        </div>
                        <div className="anti-03-holder">
                          <div className="anti-03" />
                        </div>
                        <ul className="nav">
                          <li data-target="#testimonial-1" data-slide-to="0" className="active">
                            <div>
                              <img className="img-fluid" src="images/testimonial/carlos_klein.jpeg" alt="" style={{ borderRadius: 200 }} />
                            </div>
                          </li>
                          <li data-target="#testimonial-1" data-slide-to="1">
                            <div>
                              <img className="img-fluid" src="images/testimonial/evandro_luis_moraes.jpeg" alt="" style={{ borderRadius: 200 }} />
                            </div>
                          </li>
                          <li data-target="#testimonial-1" data-slide-to="2">
                            <div>
                              <img className="img-fluid" src="images/testimonial/guilherme_pereda.jpeg" alt="" style={{ borderRadius: 200 }} />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" data-bg-img="images/bg/Asset_2.png" style={{ backgroundImage: 'url(images/bg/Asset_2.png)', backgroundRepeat: 'no-repeat', backgroundPosition: '-265px 140px', backgroundSize: '80% 80%' }}>
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-8 col-md-12 ml-auto mr-auto">
                <div className="section-title">
                  <div className="title-effect">
                    <div className="bar bar-top" />
                    <div className="bar bar-right" />
                    <div className="bar bar-bottom" />
                    <div className="bar bar-left" />
                  </div>
                  <h6 style={{ color: '#243e64' }}>{intl.get('modules.home.clientes')}</h6>
                  <h2 style={{ color: '#58595b', fontSize: 15, textAlign: 'center', lineHeight: '1.5', textTransform: 'none' }}>
                    {intl.get('modules.home.confiraQuemJaUtilizaStarGrid')}<br />
                    {intl.get('modules.home.emSuasRotinasDeTrabalho')}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="col-lg-3 col-md-12">
                <div className="post" style={{ boxShadow: 'none' }}>
                  <div className="post-image" style={{ height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src="images/banner/Asset_34.png" alt="Santa Casa" style={{ height: 150 }} />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="post" style={{ boxShadow: 'none' }}>
                  <div className="post-image" style={{ height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src="images/banner/Asset_35.png" alt="Sírio-Libanês" style={{ height: 150 }} />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="post" style={{ boxShadow: 'none' }}>
                  <div className="post-image" style={{ height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src="images/banner/Asset_36.png" alt="Moinhos de Vento" style={{ height: 150 }} />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="post" style={{ boxShadow: 'none' }}>
                  <div className="post-image" style={{ height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src="images/banner/Asset_37.png" alt="Unimed" style={{ height: 150 }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Contact />

      </div>
    </Layout>
  );
}

export default memo(HomePage);
