import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import intl from 'react-intl-universal';
import styled from 'styled-components/macro';

// Componentes
import Logo from '../icons/Logo';
import Button from '../button/Button';

function Header() {
  return (
    <header id="site-header" className="header header-1" style={{ marginTop: 15 }}>
      <div className="container-fluid">
        <div id="header-wrap" className="box-shadow">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg" style={{ height: 65 }}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <div className="navbar-brand logo" style={{ cursor: 'pointer', width: 150 }}>
                    <Logo />
                  </div>
                </Link>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul id="main-menu" className="nav navbar-nav ml-auto mr-auto">
                    <Link to="/" style={{ textDecoration: 'none' }}>
                      <li className="nav-item">
                        <div className={`nav-link ${window.location.pathname === '/' ? 'active' : ''}`} style={{ cursor: 'pointer' }}>
                          {intl.get('components.layout.header.home')}
                        </div>
                      </li>
                    </Link>
                    <Link to="/how" style={{ textDecoration: 'none' }}>
                      <li className="nav-item">
                        <div className="nav-link" style={{ cursor: 'pointer' }}>
                          {intl.get('components.layout.header.comoFunciona')}
                        </div>
                      </li>
                    </Link>
                    <Link to="/contact" style={{ textDecoration: 'none' }}>
                      <li className="nav-item">
                        <div className={`nav-link ${window.location.pathname === '/contact' ? 'active' : ''}`} style={{ cursor: 'pointer' }}>
                          {intl.get('components.layout.header.contato')}
                        </div>
                      </li>
                    </Link>
                    <Link to="/download" style={{ textDecoration: 'none' }}>
                      <li className="nav-item">
                        <div className={`nav-link ${window.location.pathname === '/download' ? 'active' : ''}`} style={{ cursor: 'pointer' }}>
                          {intl.get('components.layout.header.instale')}
                        </div>
                      </li>
                    </Link>
                    <Link to="/faq" style={{ textDecoration: 'none' }}>
                      <li className="nav-item">
                        <div className="nav-link" style={{ cursor: 'pointer' }}>
                          {intl.get('components.layout.header.faq')}
                        </div>
                      </li>
                    </Link>
                  </ul>
                </div>
                <BoxButton>
                  <a href="https://contato.stargrid.pro/demonstracao" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} onClick={() => mixpanel.track('SITE/OLD/FORM/OPEN')}>
                    <div className="btn btn-theme btn-sm navbar-collapse" data-text={intl.get('components.layout.header.soliciteDemonstracao')}>
                      <span>{intl.get('components.layout.header.soliciteDemonstracao')}</span>
                    </div>
                  </a>
                  <div style={{ width: 10 }} />
                  <a target="_blank" rel="noopener noreferrer" href="https://stargrid.app/">
                    <Button
                      id="button_header_login"
                      label={intl.get('components.layout.header.login')}
                      colors="transparent"
                    />
                  </a>
                </BoxButton>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default memo(Header);

// ==================================================
// Estilos StarGrid
// ==================================================
const BoxButton = styled.div`
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    display: none;
  }
  @media (min-width: 960px) and (max-width: 1279px) {
    display: flex;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: flex;
  }
  @media (min-width: 1920px) {
    display: flex;
  }
`;
