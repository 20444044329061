import React from 'react';

function LoaderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      style={{ margin: 'auto', background: 'none' }}
    >
      <g transform="translate(20 20)">
        <path fill="#8c57e3" d="M-15 -15H15V15H-15z" transform="scale(.57963)">
          <animateTransform
            attributeName="transform"
            begin="-0.46511627906976744s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
            keyTimes="0;0.2;0.5;0.8;1"
            repeatCount="indefinite"
            type="scale"
            values="1;1;0.2;1;1"
          />
        </path>
      </g>
      <g transform="translate(50 20)">
        <path fill="#bd9bf0" d="M-15 -15H15V15H-15z" transform="scale(.86445)">
          <animateTransform
            attributeName="transform"
            begin="-0.34883720930232553s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
            keyTimes="0;0.2;0.5;0.8;1"
            repeatCount="indefinite"
            type="scale"
            values="1;1;0.2;1;1"
          />
        </path>
      </g>
      <g transform="translate(80 20)">
        <path fill="#eecaff" d="M-15 -15H15V15H-15z">
          <animateTransform
            attributeName="transform"
            begin="-0.23255813953488372s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
            keyTimes="0;0.2;0.5;0.8;1"
            repeatCount="indefinite"
            type="scale"
            values="1;1;0.2;1;1"
          />
        </path>
      </g>
      <g transform="translate(20 50)">
        <path fill="#bd9bf0" d="M-15 -15H15V15H-15z" transform="scale(.86445)">
          <animateTransform
            attributeName="transform"
            begin="-0.34883720930232553s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
            keyTimes="0;0.2;0.5;0.8;1"
            repeatCount="indefinite"
            type="scale"
            values="1;1;0.2;1;1"
          />
        </path>
      </g>
      <g transform="translate(50 50)">
        <path fill="#eecaff" d="M-15 -15H15V15H-15z">
          <animateTransform
            attributeName="transform"
            begin="-0.23255813953488372s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
            keyTimes="0;0.2;0.5;0.8;1"
            repeatCount="indefinite"
            type="scale"
            values="1;1;0.2;1;1"
          />
        </path>
      </g>
      <g transform="translate(80 50)">
        <path fill="#8c57e3" d="M-15 -15H15V15H-15z">
          <animateTransform
            attributeName="transform"
            begin="-0.11627906976744186s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
            keyTimes="0;0.2;0.5;0.8;1"
            repeatCount="indefinite"
            type="scale"
            values="1;1;0.2;1;1"
          />
        </path>
      </g>
      <g transform="translate(20 80)">
        <path fill="#eecaff" d="M-15 -15H15V15H-15z">
          <animateTransform
            attributeName="transform"
            begin="-0.23255813953488372s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
            keyTimes="0;0.2;0.5;0.8;1"
            repeatCount="indefinite"
            type="scale"
            values="1;1;0.2;1;1"
          />
        </path>
      </g>
      <g transform="translate(50 80)">
        <path fill="#8c57e3" d="M-15 -15H15V15H-15z">
          <animateTransform
            attributeName="transform"
            begin="-0.11627906976744186s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
            keyTimes="0;0.2;0.5;0.8;1"
            repeatCount="indefinite"
            type="scale"
            values="1;1;0.2;1;1"
          />
        </path>
      </g>
      <g transform="translate(80 80)">
        <path fill="#fbf2ff" d="M-15 -15H15V15H-15z">
          <animateTransform
            attributeName="transform"
            begin="0s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
            keyTimes="0;0.2;0.5;0.8;1"
            repeatCount="indefinite"
            type="scale"
            values="1;1;0.2;1;1"
          />
        </path>
      </g>
    </svg>
  );
}

export default LoaderIcon;
