import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 90.44 91.98" {...props}>
      <defs>
        <style>{'.prefix__cls-1{fill:#00a79f}'}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M66 48.42h-1.31a2 2 0 00-1.92 1.5l-.92 3.68-3.24-2a2 2 0 00-2.17.07 1.57 1.57 0 00-.38.3l-2.79 2.79-2.72 2.67a2.54 2.54 0 00-.29.36 2 2 0 00-.1 2.19l2 3.26-3.69.89a2 2 0 00-1.49 1.61 1.76 1.76 0 00-.06.46V73.98a1.48 1.48 0 000 .47 2 2 0 001.47 1.61l3.68.92-2 3.24a2 2 0 00.28 2.42l1.28 1.3v.09l1.46 1.47 2.74 2.78a2.79 2.79 0 00.38.3 2 2 0 002.17.09l3.26-2 .89 3.14.15.59a2 2 0 002.01 1.6h7.75a1.7 1.7 0 00.46-.06 2 2 0 001.62-1.47l.93-3.68 3.24 2a2 2 0 002.17-.07 2.18 2.18 0 00.38-.3L84 85.58l2.71-2.67a2 2 0 00.29-.36 2 2 0 00.1-2.19l-1.92-3.26 3.69-.89a2 2 0 001.48-1.6 1.84 1.84 0 00.06-.47v-7.75a2.42 2.42 0 000-.47 2 2 0 00-1.48-1.61l-3.68-.92 2-3.24a2 2 0 00-.13-2.15 2 2 0 00-.29-.37l-2.73-2.77-1.6-1.63a5.81 5.81 0 00-.43-.43l-.7-.71a2 2 0 00-2.37-.31l-.44.25-3 1.62-.89-3.65a2 2 0 00-1.6-1.48 1.84 1.84 0 00-.47-.06h-6m14.68 34.3l-1.79 1.76-3-1.79a2 2 0 00-1.74-.14l-.24.1-.69.31-.69.25-.23.1a2 2 0 00-1.15 1.33L70.93 88H66.23l-.85-3.38a2 2 0 00-1.26-1.39 9.09 9.09 0 01-.73-.27h-.11l-.73-.34a1.94 1.94 0 00-1.87.07l-3 1.77-1.56-1.57-1.75-1.77 1.79-3a2 2 0 00.14-1.74 1.33 1.33 0 00-.1-.24l-.3-.69c-.09-.23-.18-.46-.26-.7a1.6 1.6 0 00-.1-.23 2 2 0 00-1.33-1.14l-3.36-.87v-4.72l3.38-.85a2 2 0 001.39-1.26c.09-.26.18-.51.28-.75s0-.07 0-.11l.33-.7a2 2 0 00-.07-1.88l-1.77-3L56 57.63l1.73-1.7 3 1.78a2 2 0 001.74.14 1.17 1.17 0 00.24-.09c.22-.11.44-.21.67-.3s.47-.18.71-.27a.94.94 0 00.23-.1 2 2 0 001.15-1.32l.86-3.36h4.71l.84 3.39a2 2 0 001.27 1.38l.75.28h.11c.24.1.47.21.7.33a2 2 0 001.88-.07l3-1.77 1.6 1.59 1.72 1.75-1.79 3A2 2 0 0081 64a1.17 1.17 0 00.09.24c.11.23.21.46.31.68s.18.47.26.71a1.6 1.6 0 00.1.23A2 2 0 0083.09 67l3.36.87v4.7l-3.38.85a2 2 0 00-1.39 1.26l-.27.73a1.17 1.17 0 01-.05.11c-.11.24-.22.48-.34.72a2 2 0 00.07 1.88l1.78 3z"
          />
          <path
            className="prefix__cls-1"
            d="M68.65 60.29a9.9 9.9 0 109.89 9.89 9.9 9.9 0 00-9.89-9.89zm0 15.83a5.94 5.94 0 115.93-5.94 5.94 5.94 0 01-5.93 5.94z"
          />
          <path
            className="prefix__cls-1"
            d="M81.61 8.9h-7.42v-3a5.94 5.94 0 10-11.87 0v3H50.45v-3a5.94 5.94 0 10-11.87 0v3H26.71v-3a5.94 5.94 0 10-11.87 0v3H7.42A7.42 7.42 0 000 16.32v59.36a7.42 7.42 0 007.42 7.42h36.15a1.49 1.49 0 000-3H7.42A4.47 4.47 0 013 75.68v-46h83.06v19.64a1.49 1.49 0 003 0v-33a7.42 7.42 0 00-7.45-7.42zm-16.32-3a3 3 0 115.93 0v8.9a3 3 0 11-5.93 0zm-23.74 0a3 3 0 115.93 0v8.9a3 3 0 11-5.93 0zm-23.74 0a3 3 0 115.93 0v8.9a3 3 0 11-5.93 0zm68.25 20.81H3V16.32a4.47 4.47 0 014.45-4.45h7.42v3a5.94 5.94 0 0011.87 0v-3h11.84v3a5.94 5.94 0 0011.87 0v-3h11.87v3a5.94 5.94 0 0011.87 0v-3h7.42a4.46 4.46 0 014.45 4.45z"
            id="prefix__Calendar"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
